import Http from '../../common/services/Http';
import { InspirationCommentsDto } from './models/InspirationCommentDto';

class InspirationCommentService {
    public create (model: InspirationCommentsDto) {
        return Http.post<string>('inspirationComments', model);
    }

    public update (model: InspirationCommentsDto) {
        return Http.put('inspirationComments', model);
    }

    public remove (model: InspirationCommentsDto) {
        return Http.put('inspirationComments/deactivate', model);
    }
}

export default new InspirationCommentService();
