import { Paged } from 'api/common/types/Page';
import CustomFile from 'common/models/CustomFile';
import Http from '../../common/services/Http';
import InspirationDto, { InspirationsDto, InspirationUserMediaDto } from './models/InspirationDto';
import { InspirationSearchCriteria } from './models/InspirationSearchCriteria';

class InspirationService {
    public getList (criteria: InspirationSearchCriteria) {
        return Http.get<Paged<InspirationDto>>('inspirations/list', criteria);
    }

    public getAllActive () {
        return Http.get<InspirationDto[]>('inspirations/get-all');
    }

    public get (id: string, clientId: string) {
        return Http.get<InspirationDto[]>(`inspirations/${id}/${clientId}`);
    }

    public create (model: InspirationsDto, attachments: CustomFile[]) {
        return Http.postWithFile<string>('inspirations', model, attachments);
    }

    public update (model: InspirationsDto, attachments: CustomFile[]) {
        return Http.putWithFile('inspirations', model, attachments);
    }

    public remove (model: InspirationsDto) {
        return Http.put('inspirations/deactivate', model);
    }

    public saveUserImage (model: InspirationUserMediaDto, mediaFile: CustomFile | null) {
        return Http.postWithFile<string>('inspirations/save-user-media', model, mediaFile ? [mediaFile] : []);
    }

    public removeUserImage (model: InspirationUserMediaDto) {
        return Http.put('inspirations/deactivate-user-media', model);
    }

    public getImageBase64FromUrl (url: string) {
        return Http.get<string>('inspirations/get-image-base64-from-url', { url });
    }
}

export default new InspirationService();
