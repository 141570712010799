
export const elementHasParent = (el: any, className: string) => {
    let parentContainer = el;
    do {
        parentContainer = parentContainer.parentNode;
    }
    while (!parentContainer.classList?.contains(className) && parentContainer !== document.body);

    return parentContainer.classList?.contains(className);
};
