import { Paged } from 'api/common/types/Page';
import CustomFile from 'common/models/CustomFile';
import Http from '../../common/services/Http';
import LanguageDto from './models/LanguageDto';
import LanguagesSearchCriteria from './models/LanguagesSearchCriteria';
import LanguageViewModel from './models/LanguageViewModel';

class LanguagesService {
    public getAll () {
        return Http.get<LanguageDto[]>('languages');
    }

    public getList (criteria?: LanguagesSearchCriteria) {
        return Http.get<Paged<LanguageDto>>('languages/list', criteria);
    }

    public get (id: string) {
        return Http.get<LanguageDto>(`languages/${id}`);
    }

    public getDefault () {
        return Http.get<LanguageDto>('languages/default');
    }

    public create (model: LanguageViewModel, logo: CustomFile | null) {
        return Http.postWithFile<string>('languages', model, logo ? [logo] : []);
    }

    public update (model: LanguageViewModel, logo: CustomFile | null) {
        return Http.putWithFile('languages', model, logo ? [logo] : []);
    }

    public deactivate (id: string) {
        return Http.put('languages/deactivate', { id });
    }
}

export default new LanguagesService();
