import Navbar from 'common/layouts/navBar/Navbar';
import { useTranslation } from 'react-i18next';
import { ApplicationPaths, LOGGER_LOG_TYPE, MAX_PAGINATION_ITEMS_PER_PAGE } from 'Config';
import { BreadCrumb } from 'common/types/BreadCrumb';
import { Reducers } from 'store/types';
import { useSelector, useDispatch } from 'react-redux';
import { ClientDto } from 'api/clients/models/ClientDto';
import ContentLayout from 'common/layouts/contentLayout/ContentLayout';
import TabsHeader from 'screens/backoffice/tabsHeader/TabsHeader';
import TabHeader from 'screens/backoffice/tabHeader/TabHeader';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DocumentsTab from './documentsTab/DocumentsTab';
import styles from './ClientFile.module.scss';
import { UserProfile } from 'api/account/models/UserProfile';
import { setChosenUser } from 'store/chosenUser/action';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';

import ClientsService from 'api/clients/ClientsService';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import toast from 'react-hot-toast';
import useNoInitialEffect from 'common/hooks/useNoInitialEffect';
import FavoritesTab from './favoritesTab/FavoritesTab';
import NotesTab from './notesTab/NotesTab';
import ClientNotesService from 'api/clientNotes/ClientNotesService';

export enum ClientFilesTab {
    DOCUMENTS,
    FAVORITES,
    NOTES,
}

function ClientFile (): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [selectedTab, setSelectedbTab] = useState<ClientFilesTab>(ClientFilesTab.FAVORITES);
    const selectedClient = useSelector<Reducers, ClientDto | null>(
        (state) => state.chosenUser.user
    );
    const [documentsCounter, setDocumentsCounter] = useState<number>();
    const [favoritesCounter, setFavoritesCounter] = useState<number>();
    const [notesCounter, setNotesCounter] = useState<number>();
    useEffect(() => {
        if (selectedClient === null) {
            navigate(ApplicationPaths.Clients);
        }
        void getCounters();
    }, [selectedClient]);

    useNoInitialEffect(() => {
        if (id === null) {
            navigate(ApplicationPaths.Clients);
        }
        void setClientbyId();
    }, [id]);

    const getCounters = async () => {
        const promises = [ClientsService.GetDocumentsCountByClient(id ?? ''), ClientsService.GetClientFavoritesCount(id ?? ''), ClientNotesService.getClienteNotesCounter(id ?? '')];

        void Promise.all(promises).then((res) => {
            setDocumentsCounter(res[0]);
            setFavoritesCounter(res[1]);
            setNotesCounter(res[2]);
        });
    };

    const setClientbyId = async () => {
        try {
            Loading.show();
            const page = await ClientsService.getList({ page: 1, itemsPerPage: MAX_PAGINATION_ITEMS_PER_PAGE });
            dispatch(setChosenUser(page.items.find(x => x.id === id) ?? null));
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get clients list", error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const breadCrumbs: BreadCrumb[] = [
        {
            text: t('menu.clients_management'),
            url: ApplicationPaths.Clients,
        },
        {
            text: t('clients.client_profile'),
        }
    ];

    const changeTab = async (newtab: ClientFilesTab) => {
        setSelectedbTab(newtab);
    };

    const loadTabs = () => {
        switch (selectedTab) {
            case ClientFilesTab.FAVORITES:
                return (<FavoritesTab clientId={id}/>);
            case ClientFilesTab.DOCUMENTS:
                return (<DocumentsTab clientId={id} userProfile={user} updateCounters={() => getCounters()}/>);
            case ClientFilesTab.NOTES:
                return (<NotesTab clientId={id} updateCounters={() => getCounters()}/>);
            default:
                return (<FavoritesTab clientId={id} />);
        }
    };

    return (
        <div>
            <Navbar breadCrumbs={breadCrumbs}/>
            <ContentLayout>
                <div className={styles.pointer}>
                    <div>
                        <MdOutlineArrowBackIosNew
                            className={styles.backIcon}
                            onClick={() => navigate(-1)}
                        /></div>
                    <span onClick={() => navigate(-1)}>{t('shared_translations.common.go_back')}</span>
                </div>
                <TabsHeader className={styles.tabsHeader}>
                    <TabHeader name={t('clients.tabs.favorites.tab_favorites')}
                        id={ClientFilesTab.FAVORITES}
                        isSelected={selectedTab === ClientFilesTab.FAVORITES}
                        counter={favoritesCounter}
                        changeTab={changeTab} />
                    <TabHeader name={t('clients.tabs.documents.tab_documents')}
                        id={ClientFilesTab.DOCUMENTS}
                        isSelected={selectedTab === ClientFilesTab.DOCUMENTS}
                        counter={documentsCounter}
                        changeTab={changeTab} />
                    <TabHeader name={t('clients.tabs.notes.tab_notes')}
                        id={ClientFilesTab.NOTES}
                        isSelected={selectedTab === ClientFilesTab.NOTES}
                        counter={notesCounter}
                        changeTab={changeTab} />
                </TabsHeader>
                <div>
                    {loadTabs()}
                </div>
            </ContentLayout>
        </div>
    );
}

export default ClientFile;
