import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddCircleIcon } from 'assets/svg/addcircle.svg';

import styles from './HomeInspirations.module.scss';
import { ApplicationPaths, LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Loading from 'common/services/Loading';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import InspirationService from 'api/inspirations/InspirationService';
import { InspirationSearchCriteria } from 'api/inspirations/models/InspirationSearchCriteria';
import InspirationDto from 'api/inspirations/models/InspirationDto';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoRecords from 'common/components/noRecords/NoRecords';
import useNoInitialEffect from 'common/hooks/useNoInitialEffect';
import EditableImage from 'common/components/editableImage/EditableImage';
import InspirationPointTooltipContent from 'common/components/editableImage/point/inspirationPointTooltipContent/InspirationPointTooltipContent';
import FloatingComment from 'screens/inspirations/favorites/floatingComment/FloatingComment';
import InspirationFavoriteService from 'api/inspirationFavorites/InspirationFavoriteService';
import LanguageDto from 'api/languages/models/LanguageDto';
import LanguagesService from 'api/languages/LanguagesService';
import InspirationFavoriteDto from 'api/inspirationFavorites/models/InspirationFavoriteDto';
import InspirationCommentService from 'api/inspirationComments/InspirationCommentService';
import InspirationCommentDto from 'api/inspirationComments/models/InspirationCommentDto';
import { ClientDto } from 'api/clients/models/ClientDto';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';

interface Props {
    masterCategoryId: string | undefined
}

function HomeInspirations ({ masterCategoryId }: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [languages, setLanguages] = useState<LanguageDto[]>([]);
    const [showFavoriteAndComment, setShowFavoriteAndComment] = useState<boolean>(false);
    const [itemFavoriteAndComment, setItemFavoriteAndComment] = useState<InspirationDto | null>(null);
    const [showRemoveFavoriteModal, setShowRemoveFavoriteModal] = useState<boolean>(false);
    const [removeMessage, setRemoveMessage] = useState<string>('');
    const [inspirationFavouriteToRemove, setInspirationFavouriteToRemove] = useState<InspirationDto | null>(null);

    const [inspirations, setInspirations] = useState<InspirationDto[]>([]);
    const [criteria, setCriteria] = useState<InspirationSearchCriteria>({
        itemsPerPage: 4,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'COALESCE(i.position, 0), title',
        categoryId: masterCategoryId
    });
    const [totalItems, setTotalItems] = useState<number>(0);

    const selectedClient = useSelector<Reducers, ClientDto | null>(
        (state) => state.chosenUser.user
    );

    useNoInitialEffect(() => {
        setCriteria({ ...criteria, categoryId: masterCategoryId, page: 1, clientId: selectedClient?.id });
    }, [selectedClient, masterCategoryId]);

    useEffect(() => {
        void getData(criteria.page === 1);
    }, [criteria]);

    useEffect(() => {
        setCriteria({ ...criteria, page: 1 });
    }, [i18n.language]);

    const getData = async (reset: boolean) => {
        try {
            Loading.show();

            const listLanguages = await LanguagesService.getAll();
            setLanguages(listLanguages);
            criteria.clientId = selectedClient?.id;
            const result = await InspirationService.getList(criteria);
            if (reset) { setInspirations(() => result.items); } else { setInspirations((insps) => [...insps, ...result.items]); }
            setTotalItems(result.totalItems);
            Loading.hide();
        } catch (error) {
            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                "Couldn't  get inspirations list",
                error
            );
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const onPageChange = () => {
        setCriteria(c => ({ ...c, page: c.page + 1 }));
    };

    const showEditLayout = useSelector<Reducers, boolean>(
        (state) => state.editLayout.open
    );

    const inspirationsLenght = inspirations.length;

    const goToDetails = (item: InspirationDto) => {
        navigate(`${ApplicationPaths.Inspirations}/details/${item.masterRecordId}`, { state: { categoryId: item.categoryId, subCategoryId: item.subCategoryId } });
    };

    const goToEdit = (item: InspirationDto) => {
        navigate(`${ApplicationPaths.Inspirations}/edit/${item.masterRecordId}`, { state: { categoryId: item.categoryId, subCategoryId: item.subCategoryId } });
    };

    const addInspirationFavorite = async (item: InspirationDto) => {
        try {
            Loading.show();
            const favorite: InspirationFavoriteDto[] = languages.map(lang => {
                return {
                    id: '',
                    inspirationId: item.masterRecordId ?? '',
                    clientId: selectedClient ? selectedClient.id : '',
                    languageId: lang.id,
                    masterRecordId: ''
                };
            });
            const masterRecordId = await InspirationFavoriteService.create({ inspirationFavorites: favorite });
            toast.success(`${t('home_screen.inspirations.add_to_favorites')}`);
            Loading.hide();
            favorite.forEach(x => { x.masterRecordId = masterRecordId; });
            return favorite;
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't create inspiration favorite", error);
            toast.error(`${t('shared_translations.messages.record_save_error')}`);
            Loading.hide();
            return [];
        }
    };

    const removeInspirationFavorite = async (item: InspirationFavoriteDto) => {
        if (item.masterRecordId && item.masterRecordId != null) {
            try {
                Loading.show();
                const masterRecordId = item.masterRecordId;
                await InspirationFavoriteService.remove({ masterRecordId, userId: selectedClient?.id ?? undefined });

                const inspirationsRemoveComments = inspirations.filter(x => x.masterRecordId === item.inspirationId);
                inspirationsRemoveComments.forEach(x => {
                    x.comments = [];
                });

                toast.success(`${t('home_screen.inspirations.remove_to_favorites')}`);
                Loading.hide();
            } catch (error) {
                toast.error(`${t('shared_translations.messages.record_delete_error')}`);
                Loading.hide();
            }
        }
    };

    const onSaveComment = async (commentText: string) => {
        try {
            Loading.show();
            const comment: InspirationCommentDto[] = languages.map(lang => {
                return {
                    id: '',
                    comment: commentText,
                    inspirationId: itemFavoriteAndComment?.masterRecordId ?? '',
                    clientId: selectedClient ? selectedClient.id : '',
                    languageId: lang.id
                };
            });
            await InspirationCommentService.create({ inspirationComments: comment });

            if (itemFavoriteAndComment?.masterRecordId) {
                const inspirationsComment = inspirations.filter(x => x.masterRecordId === itemFavoriteAndComment?.masterRecordId);
                inspirationsComment.forEach(x => {
                    const commentLanguage = comment.find(c => c.languageId === x.languageId);
                    if (commentLanguage && x.comments) {
                        x.comments.push(commentLanguage);
                    }
                    if (commentLanguage && !x.comments) {
                        x.comments = [];
                        x.comments.push(commentLanguage);
                    }
                });
            }

            toast.success(`${t('shared_translations.messages.record_save_success')}`);
            setShowFavoriteAndComment(false);
            setItemFavoriteAndComment(null);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't create inspiration comment", error);
            toast.error(`${t('shared_translations.messages.record_save_error')}`);
            Loading.hide();
        }
    };

    const onAddInspirationFavorite = async (item: InspirationDto) => {
        item.favorites = await addInspirationFavorite(item);
        setShowFavoriteAndComment(true);
        setItemFavoriteAndComment(item);
    };

    const onRemoveInspirationFavorite = async () => {
        if (inspirationFavouriteToRemove) {
            setItemFavoriteAndComment(inspirationFavouriteToRemove);
            inspirationFavouriteToRemove.favorites && await removeInspirationFavorite(inspirationFavouriteToRemove.favorites[0]);
            inspirationFavouriteToRemove.favorites = [];
            setShowFavoriteAndComment(false);
            setItemFavoriteAndComment(null);

            setShowRemoveFavoriteModal(false);
        }
    };

    const showRemoveFavoriteDialog = async (item: InspirationDto) => {
        let message = '';

        const commentsNumber = item.comments ? item.comments.length : 0;
        const imagesNumber = item.attachments?.filter(x => x.attachment?.userId === selectedClient?.id).length;

        if (commentsNumber > 0 && (imagesNumber && imagesNumber > 0)) {
            message = t('home_screen.inspirations.associations_favorite_comments_images', { commentsNumber, imagesNumber });
        }
        if (commentsNumber > 0 && !imagesNumber) {
            message = t('home_screen.inspirations.associations_favorite_comments', { commentsNumber });
        }
        if (commentsNumber <= 0 && (imagesNumber && imagesNumber > 0)) {
            message = t('home_screen.inspirations.associations_favorite_images', { imagesNumber });
        }
        setInspirationFavouriteToRemove(item);
        setRemoveMessage(message);
        setShowRemoveFavoriteModal(true);
    };

    const onCancelRemoveFavorite = () => {
        setShowRemoveFavoriteModal(false);
        setInspirationFavouriteToRemove(null);
    };

    return (
        <div className={styles.mainDiv}>
            <span className={styles.span}>
                {t('home_screen.inspirations.inspirations_title')}

                {showEditLayout && (
                    <AddCircleIcon
                        onClick={() => navigate(`${ApplicationPaths.Inspirations}/create`)}
                        style={{ cursor: 'pointer' }}
                    />
                )}
            </span>
            {inspirationsLenght > 0 && (
                <InfiniteScroll
                    dataLength={inspirationsLenght}
                    loader={null}
                    hasMore={inspirationsLenght < totalItems}
                    next={onPageChange}
                    scrollableTarget={'tableScroll'}
                >
                    <div className={styles.grid}>
                        {inspirations.map((item, index) => (
                            <div key={index} className={styles.inspirationCard}>
                                <EditableImage
                                    url={item.mediaUrl ?? ''}
                                    points={item.mediaPoints ?? []}
                                    disabled={true}
                                    renderPointTooltip={(pointData) => pointData.name
                                        ? <InspirationPointTooltipContent
                                            title={pointData.name}
                                            subtitle={pointData.description}
                                            price={pointData.price}
                                        />
                                        : null}
                                    editMode={'none'}
                                    showEditNavigationButton={showEditLayout}
                                    onClick={() => goToDetails(item)}
                                    onEditNavigationButtonClick={() => goToEdit(item)}
                                    allowDrawing={false}
                                    onAddInspirationFavorite={() => onAddInspirationFavorite(item)}
                                    onRemoveInspirationFavorite={() => showRemoveFavoriteDialog(item)
                                        // () => onRemoveInspirationFavorite(item)
                                    }
                                    favorites={item.favorites ?? []}
                                    isClientSelected={!!selectedClient}
                                />
                                <div className={styles.title}>{item.title}</div>
                                <div className={styles.description}> {item.description}</div>
                            </div>
                        ))}
                    </div>
                </InfiniteScroll>
            )}
            {inspirationsLenght <= 0 && <NoRecords />}
            {showFavoriteAndComment && itemFavoriteAndComment && <FloatingComment item={itemFavoriteAndComment} onClose={() => setShowFavoriteAndComment(false)} onSaveComment={comment => onSaveComment(comment)} />}

            <QuestionYesNo
                onNo={onCancelRemoveFavorite}
                onYes={() => onRemoveInspirationFavorite()}
                isVisible={showRemoveFavoriteModal}
                message={t('home_screen.inspirations.remove_from_favorites')}
                messageLine2={removeMessage}
            />
        </div>
    );
}

export default HomeInspirations;
