import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import { RoleDto } from 'api/roles/models/RoleDto';
import RolesService from 'api/roles/RolesService';
import BackofficeTable, { BackofficeTableColumn } from 'common/components/backofficeTable/BackofficeTable';
import Loading from 'common/services/Loading';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, ApplicationPaths, LOGGER_LOG_TYPE } from 'Config';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Logger from 'common/services/Logger';
import { ReactComponent as ActionsIcon } from 'assets/svg/more-vertical.svg';
import styles from './RoleTab.module.scss';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import hasPolicies from 'common/utils/hasPolicies';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';

interface Props {
    searchString: string
    searchOrder: boolean
}

const RolesTab = (props: Props) => {
    const { searchString, searchOrder } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(user, ['SETTINGUP_ROLES_WRITE']);
    const [roles, setRoles] = useState<RoleDto[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [criteria, setCriteria] = useState<PagedSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'real_name',
    });

    let allInField = searchString;

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<RoleDto | null>(null);

    useEffect(() => {
        allInField = searchString;
        void getRoleList(criteria.page === 1);
    }, [criteria]);

    useEffect(() => {
        setCriteria({ ...criteria, page: 1 });
        void getRoleList(true);
    }, [searchOrder]);

    const showRemoveItemDialog = (item: RoleDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const removeItem = async () => {
        if (!itemToRemove) {
            return;
        }

        try {
            Loading.show();
            await RolesService.remove(itemToRemove);
            toast.success(`${t('shared_translations.messages.record_delete_success')}`);
            setShowRemoveModal(false);
            changePage(1);
            Loading.hide();
        } catch (error) {
            setShowRemoveModal(false);
            toast.error(`${t('shared_translations.messages.record_delete_error')}`);
            setShowRemoveModal(false);
            Loading.hide();
        }
    };

    const changePage = (page: number) => {
        setCriteria({ ...criteria, page });
    };

    const getRoleList = async (reset = false) => {
        try {
            Loading.show();
            const page = await RolesService.getList({ ...criteria, allIn: allInField });
            if (reset) {
                setRoles(page.items);
            } else {
                setRoles([...roles, ...page.items]);
            }
            setTotalItems(page.totalItems);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get roles list", error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const orderTable = async (orderField: string) => {
        const page = 1;
        const orderColumn = orderField;
        let orderBy: string;

        if (orderColumn !== criteria.orderColumn) {
            orderBy = 'asc';
        } else {
            if (criteria.orderBy === 'asc') {
                orderBy = 'desc';
            } else {
                orderBy = 'asc';
            }
        }
        setCriteria({ ...criteria, page, orderColumn, orderBy });
    };

    const renderActionCell = (row: RoleDto) => {
        return (
            <div className={styles.optionsIcon}>
                <Dropdown
                    options={
                        <>
                            <DropdownItem url={`${ApplicationPaths.Backoffice}/roles/details/${row.id}`}>
                                {t('shared_translations.common.details')}
                            </DropdownItem>
                            { canWrite &&
                                <DropdownItem url={`${ApplicationPaths.Backoffice}/roles/edit/${row.id}`}>
                                    {t('shared_translations.common.edit')}
                                </DropdownItem>
                            }
                            { canWrite &&
                                <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                                    {t('shared_translations.common.delete')}
                                </DropdownItem>
                            }
                        </>
                    }
                >
                    <div>
                        <ActionsIcon />
                    </div>
                </Dropdown>
            </div>
        );
    };

    const RolesColumn: BackofficeTableColumn<RoleDto>[] = [
        {
            name: t('backoffice.roles.form.name'),
            renderCell: (row) => row.realName,
            width: '100%',
            hideOn: [],
            columnName: 'real_name',
        },
        {
            name: '',
            renderCell: renderActionCell,
            width: 'fill',
            columnName: '',
            preventClick: true,
        },
    ];

    return (
        <div>
            <BackofficeTable
                columns={RolesColumn}
                rows={roles}
                totalItems={totalItems}
                currentPage={criteria.page}
                changePage={changePage}
                orderTable={orderTable}
                navigateTo={`${ApplicationPaths.Backoffice}/roles/create`}
                initialOrder={{ columnName: 'name', isOrderAsc: true }}
                onRowClick={row => navigate(`${ApplicationPaths.Backoffice}/roles/details/${row.id}`)}
                showAddButton={canWrite}
            />
            <QuestionYesNo
                onNo={onCancelRemove}
                onYes={removeItem}
                isVisible={showRemoveModal}
                message={t('shared_translations.messages.remove_record_with_ident', {
                    name: itemToRemove?.name ?? '',
                })}
            />
        </div>
    );
};

export default RolesTab;
