import React from 'react';
import styles from './FormItem.module.scss';

interface Props {
    children: any
}

const FormItem = (props: Props) => (
    <div className={styles.formItem}>
        {props.children}
    </div>
);

export default FormItem;
