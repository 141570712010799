import { Action } from './../authentication/type';
const initialState = {
    user: null
};

export default function (state = initialState, action: Action) {
    if (action.type === 'changeUser') {
        return { ...state, user: action.user };
    } else {
        return state;
    }
}
