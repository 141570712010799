import Http from 'common/services/Http';

class MediaService {
    public downloadMedia (mediaId: string): Promise<Blob> {
        return Http.getWithConfig('medias/get-by-id/' + mediaId, null, { responseType: 'blob' });
    }

    public downloadByUrl (url: string, fileName: string): Promise<Blob> {
        return Http.postWithConfig('medias/download-by-url', { url, fileName }, { responseType: 'blob' });
    }
}

export default new MediaService();
