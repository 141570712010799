import { centerCrop, makeAspectCrop, PixelCrop } from 'react-image-crop';

export function centerAspectCrop (
    cropWidth: number,
    cropHeight: number,
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
    byPx: boolean,
) {
    if (byPx) {
        const res: PixelCrop = {
            unit: 'px',
            width: cropWidth,
            height: cropHeight,
            x: 10,
            y: 10,
        };
        return res;
    }
    return centerCrop(
        makeAspectCrop(
            { unit: '%', width: 90, height: 90 },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    );
}
