import CustomFile from 'common/models/CustomFile';
import Http from 'common/services/Http';
import { AssociatedToCategoryDto, CategorieDto, CategoriesDto } from './models/CategorieDto';
import { CategorySearchCriteria } from './models/CategorySearchCriteria';

class CategoriesService {
    public getList (criteria: CategorySearchCriteria) {
        return Http.get<CategorieDto[]>('categories/list', criteria);
    }

    public get (id: string) {
        return Http.get<CategorieDto[]>(`categories/${id}`);
    }

    public getByMasterRecordParentId (id: string) {
        return Http.get<CategorieDto[]>(`categories/get-by-master-record-parent-id/${id}`);
    }

    public getAllActive () {
        return Http.get<CategorieDto[]>('categories/get-all');
    }

    public getAssociations (id: string) {
        return Http.get<AssociatedToCategoryDto>(`categories/get-associations/${id}`);
    }

    public create (model: CategoriesDto, mediaFile: CustomFile[]) {
        return Http.postWithFile('categories', model, mediaFile);
    }

    public update (model: CategoriesDto, mediaFile: CustomFile[]) {
        return Http.putWithFile('categories', model, mediaFile);
    }

    public remove (model: CategoriesDto) {
        return Http.put('categories/deactivate', model);
    }
}

export default new CategoriesService();
