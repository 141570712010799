import { UserProfile } from 'api/account/models/UserProfile';
import { ReactComponent as Chevron } from 'assets/svg/chevronupdown.svg';
import { ReactComponent as CloseButton } from 'assets/svg/x_mobile.svg';
import { API_BASE_URL, POLICIES } from 'Config';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineGlobeAlt } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { changeSideBar } from 'store/sideBar/action';
import { Reducers } from 'store/types';

import LanguagesService from 'api/languages/LanguagesService';
import LanguageDto from 'api/languages/models/LanguageDto';
import hasPolicies from 'common/utils/hasPolicies';
import styles from './Sidebar.module.scss';
import { CategorieDto } from 'api/categories/models/CategorieDto';

export interface SidebarItem {
    text: string;
    url: string;
    subNav?: CategorieDto[];
    policies?: (keyof typeof POLICIES)[];
}

interface Props {
    items: SidebarItem[]
    onClose: () => void
}

const Sidebar: React.FunctionComponent<Props> = ({ items, onClose }: Props) => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const [languageWindow, setLanguageWindow] = useState(false);
    const [languages, setLanguages] = useState<LanguageDto[]>([]);

    const user = useSelector<Reducers, UserProfile | null>(
        (state) => state.authentication.profile
    );

    useEffect(() => {
        void getLanguages();
    }, []);

    const getLanguages = async () => {
        const listLanguages = await LanguagesService.getAll();
        setLanguages(listLanguages);

        if (!i18n.language) {
            const languageByBrowser = listLanguages.find((x) =>
                window.navigator.language.toLowerCase().includes(x.iso.toLowerCase())
            );

            if (languageByBrowser) {
                void i18n.changeLanguage(languageByBrowser.iso);
            } else {
                const languageDefault = listLanguages.find((x) => x.isDefault);
                void i18n.changeLanguage(
                    languageDefault ? languageDefault.iso : listLanguages[0].iso
                );
            }
        }
    };

    if (user == null) {
        return <Navigate replace={true} to="/login" />;
    }

    const LoadList = (item: SidebarItem, i: number) => {
        const [open, setOpen] = useState(false);

        return (
            <div
                key={i}
                className={item.subNav && open ? styles.colapseDiv : styles.item}
            >
                <div
                    className={styles.closable}
                    onClick={() => setOpen(!open)}
                    style={item.subNav ? { cursor: 'pointer' } : {}}
                >
                    {item.subNav
                        ? (
                            <a>{item.text}</a>
                        )
                        : (
                            <Link to={item.url} onClick={() => onClose() }>{item.text}</Link>
                        )}

                    {item.subNav && (
                        <Chevron className={`${open ? '' : styles.rotate}`} />
                    )}
                </div>

                {item.subNav && open && loadSub(item.subNav)}
            </div>
        );
    };

    const loadSub = (item: CategorieDto[]) => {
        return item.map((sub, i) => (
            <div key={i} className={styles.subNav}>
                <Link to={`/home/${sub.masterRecordId}`} onClick={onClose}>{sub.name}</Link>
            </div>
        ));
    };

    const onChangeLanguage = async (language: LanguageDto) => {
        setLanguageWindow(false);
        void i18n.changeLanguage(language.iso);
    };

    const loadLanguageOptions = () => {
        return (
            <div className={styles.languageDiv} >
                {languages.map((lang, index) => (
                    <div key={index} className={styles.languageRow} onClick={() => onChangeLanguage(lang)}>
                        <input
                            type="radio"
                            id={lang.iso}
                            name="lang"
                            value={lang.iso}
                            checked={i18n.language === lang.iso}
                            onChange={() => onChangeLanguage(lang)}
                        />
                        <label htmlFor={lang.iso} >
                            {lang.name} -{' '}
                            <span className={styles.languageIso}>{lang.iso}</span>
                        </label>
                    </div>
                ))}
            </div>
        );
    };

    const filterByPolicies = (item: SidebarItem) => {
        if (item.policies && item.policies.length > 0) {
            return hasPolicies(user, item.policies);
        }
        return true;
    };

    return (
        <div className={styles.overflow}>
            <div
                className={styles.overlay}
                onClick={() => dispatch(changeSideBar())}
            />
            <div className={styles.container}>
                <div className={styles.innerContainer}>
                    <div className={styles.logoContainer} style={{ backgroundImage: `url("${API_BASE_URL}/contentconfigurations/media-by-internal-finder/logo")` }} />
                    <div className={styles.itemsContainer}>
                        <div className={styles.listContainer}>
                            {items.filter(filterByPolicies).map((item, i) => LoadList(item, i))}
                        </div>
                        <div
                            className={styles.languageIcon}
                            onClick={() => {
                                setLanguageWindow(!languageWindow);
                            }}
                        >
                            <div className={styles.closable}>
                                <div className={styles.leftDiv}>
                                    <HiOutlineGlobeAlt
                                        style={{ width: '1.875rem', height: '1.875rem' }}
                                    />
                                    <div className={styles.text}>
                                        {
                                            languages.find(
                                                (x) =>
                                                    x.iso.toUpperCase() === i18n.language.toUpperCase()
                                            )?.name
                                        }
                                    </div>
                                </div>

                                <div className={`${languageWindow ? '' : styles.rotate}`}>
                                    <Chevron />
                                </div>
                            </div>
                            {languageWindow && loadLanguageOptions()}
                        </div>
                    </div>
                </div>
                <div
                    className={styles.button}
                    onClick={() => dispatch(changeSideBar())}
                >
                    <CloseButton />
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
