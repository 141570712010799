import CustomFile from 'common/models/CustomFile';
import Loading from 'common/services/Loading';
import { createImageCanvas } from 'common/utils/canvasPreview';
import dataURLtoFile from 'common/utils/dataURLtoFile';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import Button, { Color } from '../buttons/Button';
import Modal from '../modal/Modal';
import styles from './ImageCropModal.module.scss';
import loadImage from 'blueimp-load-image';
import { centerAspectCrop } from '../imageCrop/ImageCropUtils';

const defaultModalPadding = 100;
const maxImageSize = 1000;

interface Props {
    isOpen: boolean;
    isModal: boolean;
    imageUrl?: string;
    imageFile?: CustomFile;
    circularCrop?: boolean;
    rectangularShape?: boolean;
    legend?: string;
    onClose?: () => void;
    onSave?: (imageFile: CustomFile, dataUrl: string) => void;
    onCompletedCrop?: (crop: PixelCrop) => void;
}

const ImageCropModal: React.FC<Props> = ({ isOpen, isModal, onClose, onSave, imageUrl, imageFile, circularCrop = true, rectangularShape = false, onCompletedCrop, legend }: Props) => {
    const { t } = useTranslation();
    const [crop, setCrop] = useState<Crop>();
    const [imageSrc, setImageSrc] = useState('');
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const imgRef = useRef<HTMLImageElement>(null);
    const modalContainer = useRef<HTMLDivElement | null>(null);
    const modalMinHeight = useRef(0);

    const landscapeWidth = 16;
    const landscapeHeight = 9;
    const landscapeSize = landscapeWidth / landscapeHeight;

    const applyCompletedCrop = (c: PixelCrop) => {
        setCompletedCrop({ ...c, height: Math.round(c.height), width: Math.round(c.width) });
    };

    const readImageFile = () => {
        setCrop(undefined);

        if (imageUrl) {
            setImageSrc(imageUrl);
        } else {
            if (imageFile) {
                loadImage(imageFile, (eventOrImage: Event | HTMLCanvasElement | HTMLImageElement) => {
                    const base64Res = (eventOrImage as any).toDataURL();
                    if (!modalMinHeight.current) {
                        modalMinHeight.current = (modalContainer.current?.clientHeight ?? 0) + defaultModalPadding;
                    }
                    setImageSrc(base64Res);
                }, {
                    orientation: true,
                    canvas: true,
                    maxWidth: 2000,
                    maxHeight: 2000,
                });
            }
        }
    };

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const { width, height, naturalWidth, clientWidth } = e.currentTarget;
        const _scaleRatio = naturalWidth / clientWidth;

        const useMaxSize = false;

        const centeredCrop = centerAspectCrop(
            useMaxSize ? maxImageSize / _scaleRatio : width,
            useMaxSize ? maxImageSize / _scaleRatio : height,
            width,
            height,
            rectangularShape ? landscapeSize : 1,
            useMaxSize
        );

        setCrop(centeredCrop);

        if (centeredCrop.unit === 'px') {
            applyCompletedCrop(centeredCrop);
        }

        Loading.hide();
    };

    const cancel = () => {
        if (onClose) {
            onClose();
        }
    };

    const save = async () => {
        if (!imgRef || !imgRef.current || !completedCrop || !imageFile) {
            return;
        }

        Loading.show();

        const reader = new FileReader();
        const canvas = await createImageCanvas(imgRef.current, completedCrop, 1, 0);
        canvas.toBlob(blob => {
            if (!blob) {
                Loading.hide();
                return;
            }
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                if (!reader.result) {
                    Loading.hide();
                    return;
                }

                const dataUrl = reader.result as string;
                const croppedFile = dataURLtoFile(dataUrl, imageFile.name);
                if (!croppedFile) {
                    Loading.hide();
                    return;
                }

                if (onSave) {
                    onSave(croppedFile, dataUrl);
                }
                Loading.hide();
            };
        });
    };

    useEffect(() => {
        if (!imageFile && !imageUrl) {
            return;
        }
        Loading.show();
        readImageFile();
    }, [imageFile, imageUrl]);

    return (
        <div>
            {isModal && <Modal isOpen={isOpen} onRequestClose={cancel} style={{ content: { padding: 0, width: '88%' } }} shouldCloseOnOverlayClick={false}>
                <div ref={modalContainer}>
                    <div className={styles.header}>
                        {t('shared_translations.crop_image.title')}
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.body}>
                        <div className={styles.cropContainer}>
                            {Boolean(imageSrc) && <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => applyCompletedCrop(c)}
                                aspect={rectangularShape ? landscapeSize : 1}
                                circularCrop={circularCrop}
                                keepSelection={true}
                                style={{ maxHeight: `calc(100vh - ${modalMinHeight.current}px)` }}
                            >
                                <img
                                    ref={imgRef}
                                    src={imageSrc}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>}
                        </div>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.bottom}>
                        <Button text={t('shared_translations.common.cancel')} color={Color.white} onClick={cancel} />
                        <Button text={t('shared_translations.common.save')} color={Color.black} onClick={save} />
                    </div>
                </div>
            </Modal>}
            {!isModal && Boolean(imageSrc) && <div className={styles.containerCrop}>
                { legend && <span className={styles.labelCrop}>
                    {legend}
                </span> }
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => {
                        applyCompletedCrop(c);
                        if (onCompletedCrop) {
                            onCompletedCrop(c);
                        }
                    }}
                    circularCrop={circularCrop}
                    keepSelection={true}
                    style={{ maxHeight: `calc(100vh - ${modalMinHeight.current}px)` }}
                >
                    <img
                        ref={imgRef}
                        src={imageSrc}
                        onLoad={onImageLoad}
                        style={{ width: 2000 }}
                    />
                </ReactCrop>
            </div>
            }
        </div>
    );
};

export default ImageCropModal;
