import ImageCropModal from 'common/components/imageCropModal/ImageCropModal';
import CustomFile from 'common/models/CustomFile';
import fileToDataUrl from 'common/utils/fileToDataUrl';
import { useState } from 'react';
import IconInput from './IconInput';

interface Props {
    onChooseFile?: (file: CustomFile, dataUrl: string) => void;
    cropImage?: boolean;
    circularCrop?: boolean;
    rectangularShape?: boolean
}

const UploaderIcon = ({
    onChooseFile, circularCrop, rectangularShape = false, cropImage = true
}: Props) => {
    const [avatarOriginalImage, setAvatarOriginalImage] = useState<CustomFile | null>(null);
    const [showAvatarCropModal, setShowAvatarCropModal] = useState(false);
    const onChooseAvatar = async (file: CustomFile) => {
        if (cropImage) {
            setAvatarOriginalImage(file);
            setShowAvatarCropModal(true);
        } else {
            const dataUrl = await fileToDataUrl(file);
            if (onChooseFile) { onChooseFile(file, dataUrl); }
        }
    };

    const onCloseAvatarCropModal = () => {
        setAvatarOriginalImage(null);
        setShowAvatarCropModal(false);
    };

    const onCropAvatar = (file: CustomFile, dataUrl: string) => {
        onCloseAvatarCropModal();
        if (onChooseFile) { onChooseFile(file, dataUrl); }
    };

    return (
        <div>
            <IconInput
                onChooseFile={onChooseAvatar}
            />
            {cropImage && avatarOriginalImage !== null && (
                <ImageCropModal
                    isOpen={showAvatarCropModal}
                    isModal={true}
                    imageFile={avatarOriginalImage}
                    onClose={onCloseAvatarCropModal}
                    onSave={onCropAvatar}
                    circularCrop={circularCrop}
                    rectangularShape={rectangularShape}
                />
            )}
        </div>
    );
};

export default UploaderIcon;
