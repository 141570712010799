import styles from './DropdownButton.module.scss';
import { ReactComponent as ChevronDownIcon } from 'assets/svg/chevron_down.svg';
import { CSSProperties, useRef, useState } from 'react';
import DropdownColorSizeContent, { DropdownColorSizeValue } from './DropdownColorSizeContent';
import useClickOutsideMultiple from 'common/hooks/useClickOutsideMultiple';
import { Portal } from 'react-portal';

type Props = {
    children: JSX.Element;
    active: boolean;
    activeColor: string;
    inactiveColor: string;
    iconContainerStyle?: CSSProperties;
    onClick: () => void;
} & ({
    dropdown?: null;
    value?: null;
    onChange?: null;
    colors?: null;
    sizes?: null;
    dropdownClassName?: null;
} | {
    dropdown: 'color-size';
    value: DropdownColorSizeValue;
    colors: string[];
    sizes: number[];
    dropdownClassName?: string;
    onChange: (value: DropdownColorSizeValue) => void;
});

function DropdownButton ({ children, dropdown, onClick, value, onChange, colors, sizes, active, activeColor, inactiveColor, dropdownClassName, iconContainerStyle }: Props): JSX.Element {
    const [showDropdown, setShowDropdown] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const onClickHandler = () => {
        onClick();
        setShowDropdown(!showDropdown);
    };

    useClickOutsideMultiple([containerRef, dropdownRef], () => {
        if (showDropdown) {
            setShowDropdown(false);
        }
    }, [showDropdown]);

    const offset = containerRef.current?.getBoundingClientRect();

    return (
        <div className={styles.container} onClick={onClickHandler} ref={containerRef}>
            <div className={styles.iconContainer} style={iconContainerStyle}>
                {children}
                {dropdown && <ChevronDownIcon
                    className={styles.dropdownIcon}
                    fill={active ? activeColor : inactiveColor }
                />}
            </div>
            {showDropdown && <Portal>
                <div
                    className={`${styles.dropdown} ${dropdownClassName ?? ''}`}
                    style={offset
                        ? {
                            top: offset.top + offset.height,
                            left: offset.left
                        }
                        : {}}
                    ref={dropdownRef}
                >
                    {dropdown === 'color-size' && <DropdownColorSizeContent onChange={onChange} value={value} colors={colors} sizes={sizes} />}
                </div>
            </Portal>}
        </div>
    );
}

export default DropdownButton;
