import React, { memo, useRef } from 'react';
import styles from './InputWithButton.module.scss';
import ArrowIcon from 'assets/svg/arrow.svg';
import WhiteArrowIcon from 'assets/svg/arrow-white.svg';

export type Props = {
    placeholder: string;
    type?: string;
    className?: string;
    lightInput?: boolean;
    onButtonClick?: () => void;
    onChange: (e: any) => void;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const InputWithButton = ({ placeholder, lightInput, onButtonClick, onChange, ...props }: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div>
            <div className={styles.inputContainer} style={{ backgroundColor: lightInput ? '#F5F5F5' : undefined }}>
                <input
                    {...props}
                    ref={inputRef}
                    type={props.type ?? 'text'}
                    placeholder={placeholder}
                    className={`${styles.inputContent} ${props.className ?? ''}`}
                    onChange={(e) => onChange(e)}
                    style={{ backgroundColor: lightInput ? '#F5F5F5' : undefined, color: lightInput ? '#787878' : undefined }}
                />
                <div className={styles.containerImage} style={{ backgroundColor: lightInput ? '#000000' : undefined }} onClick={onButtonClick ?? undefined}>
                    <img className={styles.image} src={lightInput ? WhiteArrowIcon : ArrowIcon} />
                </div>
            </div>

        </div>
    );
};

export default memo(InputWithButton);
