import Http from '../../common/services/Http';
import { RuleDto } from './models/RuleDto';

class RulesService {
    public getAll () {
        return Http.get<RuleDto[]>('rules/all');
    }

    public update (model: RuleDto[]) {
        return Http.put('rules', { rules: model });
    }
}

export default new RulesService();
