import { fabric } from 'fabric';

const half = 2;

export const setBackgroundFromUrl = (canvas: fabric.Canvas, dataUrl: string, maxWidth: number, maxHeight: number) => {
    if (!dataUrl) { return; }

    const img = new Image();
    img.onload = () => {
        if (!canvas) {
            return;
        }

        const imgWidth = img.width; // Current image width
        const imgHeight = img.height; // Current image height

        const widthAspectRatio = maxWidth / imgWidth;
        const heightAspectRatio = maxHeight / imgHeight;

        const finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);

        const finalHeight = imgHeight * finalAspectRatio;
        const finalWidth = imgWidth * finalAspectRatio;

        let imgTop = 0;
        if (maxHeight > finalHeight) {
            imgTop = (Math.round(maxHeight) - Math.round(finalHeight)) / half;
        }

        let imgLeft = 0;
        if (maxWidth > finalWidth) {
            imgLeft = (Math.round(maxWidth) - Math.round(finalWidth)) / half;
        }

        const nsgImage = new fabric.Image(img).scale(finalAspectRatio);
        nsgImage.set({ left: imgLeft, top: imgTop });

        canvas.setBackgroundImage(nsgImage as fabric.Image, () => {
            if (!canvas) {
                return;
            }
            canvas.renderAll();
        });
    };

    img.src = dataUrl;

    // fabricCanvasRef.current.setBackgroundImage(url, () => {
    //     if (!fabricCanvasRef.current || !fabricCanvasRef.current.backgroundImage) {
    //         return;
    //     }
    //     console.log('fabricCanvasRef.current.backgroundImage', fabricCanvasRef.current.backgroundImage);
    //     (fabricCanvasRef.current.backgroundImage as any).scaleToWidth(width);
    //     (fabricCanvasRef.current.backgroundImage as any).scaleToHeight(height);
    //     fabricCanvasRef.current.setDimensions({ width, height });
    //     fabricCanvasRef.current.renderAll();
    // });
};

export const setObjectsSelection = (canvas: fabric.Canvas, value: boolean, render = true, hoverCursorMove = false) => {
    if (hoverCursorMove) {
        canvas.defaultCursor = 'move';
    } else {
        canvas.defaultCursor = 'default';
    }

    canvas.forEachObject(obj => {
        obj.selectable = value;
        obj.hoverCursor = (value || hoverCursorMove ? 'move' : 'default');
    });

    if (render) {
        canvas.renderAll();
    }
};

export const removeEvents = (canvas: fabric.Canvas) => {
    canvas.off('mouse:down');
    canvas.off('mouse:up');
    canvas.off('mouse:move');
    canvas.off('touch:drag');
};

export const getData = (canvas: fabric.Canvas) => {
    if (!canvas) {
        return '';
    }

    const data = canvas.toJSON(['width', 'height']);
    return JSON.stringify(data);
};

export const loadData = (canvas: fabric.Canvas, data: string | null, cb?: () => void) => {
    if (!canvas || !data) {
        if (cb) {
            cb();
        }
        return;
    }

    const dataObj = JSON.parse(data);
    if (!dataObj) {
        if (cb) {
            cb();
        }
        return;
    }

    const oldWidth = dataObj.width;
    const oldHeight = dataObj.height;

    delete dataObj.width;
    delete dataObj.height;

    canvas.loadFromJSON(dataObj, () => {
        if (!canvas) {
            if (cb) {
                cb();
            }
            return;
        }

        resizeViewport(canvas, oldWidth, oldHeight);

        if (cb) {
            cb();
        }

        canvas.renderAll();
    });
};

export const resizeViewport = (canvas: fabric.Canvas, oldWidth: number, oldHeight: number) => {
    const scaleX = (canvas.width ?? 0) / oldWidth;
    const scaleY = (canvas.height ?? 0) / oldHeight;

    const objects = canvas.getObjects();
    for (const obj of objects) {
        obj.scaleX = (obj.scaleX ?? 0) * scaleX;
        obj.scaleY = (obj.scaleY ?? 0) * scaleY;
        obj.left = (obj.left ?? 0) * scaleX;
        obj.top = (obj.top ?? 0) * scaleY;
        obj.setCoords();
    }
};

export const zoomToCenter = (canvas: fabric.Canvas, zoomPercentage: number) => {
    canvas.zoomToPoint(new fabric.Point((canvas.width ?? 0) / half, (canvas.height ?? 0) / half), (canvas.getZoom() / zoomPercentage));
};
