import CustomFile from 'common/models/CustomFile';
import Loading from 'common/services/Loading';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import loadImage from 'blueimp-load-image';
import { centerAspectCrop } from './ImageCropUtils';
import { createImageCanvas } from 'common/utils/canvasPreview';
import dataURLtoFile from 'common/utils/dataURLtoFile';

const maxImageSize = 1000;

export interface ImageCropRef {
    getImageFile: (onSuccess: (f: CustomFile, u: string) => void, onError: () => void) => void;
}

interface Props {
    imageUrl?: string;
    imageFile?: CustomFile;
    circularCrop?: boolean;
    rectangularShape?: boolean;
    maxWidth?: number;
    maxHeight?: number;
    onCompletedCrop?: (crop: PixelCrop) => void;
}

const ImageCrop = ({ imageUrl, imageFile, circularCrop = true, rectangularShape = false, onCompletedCrop, maxWidth, maxHeight }: Props, ref: React.Ref<ImageCropRef>) => {
    const [crop, setCrop] = useState<Crop>();
    const [imageSrc, setImageSrc] = useState('');
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const imgRef = useRef<HTMLImageElement>(null);

    const landscapeWidth = 16;
    const landscapeHeight = 9;
    const landscapeSize = landscapeWidth / landscapeHeight;

    const applyCompletedCrop = (c: PixelCrop) => {
        setCompletedCrop({ ...c, height: Math.round(c.height), width: Math.round(c.width) });
    };

    const readImageFile = () => {
        setCrop(undefined);

        if (imageUrl) {
            setImageSrc(imageUrl);
        } else {
            if (imageFile) {
                loadImage(imageFile, (eventOrImage: Event | HTMLCanvasElement | HTMLImageElement) => {
                    const base64Res = (eventOrImage as any).toDataURL();
                    setImageSrc(base64Res);
                }, {
                    orientation: true,
                    canvas: true,
                    maxWidth: 2000,
                    maxHeight: 2000,
                });
            }
        }
    };

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const { width, height, naturalWidth, clientWidth } = e.currentTarget;
        const _scaleRatio = naturalWidth / clientWidth;

        const useMaxSize = false;

        const centeredCrop = centerAspectCrop(
            useMaxSize ? maxImageSize / _scaleRatio : width,
            useMaxSize ? maxImageSize / _scaleRatio : height,
            width,
            height,
            rectangularShape ? landscapeSize : 1,
            useMaxSize
        );

        setCrop(centeredCrop);

        if (centeredCrop.unit === 'px') {
            applyCompletedCrop(centeredCrop);
        }

        Loading.hide();
    };

    useEffect(() => {
        if (!imageUrl) {
            return;
        }
        Loading.show();
        readImageFile();
    }, [imageFile, imageUrl]);

    useImperativeHandle(ref, () => ({
        getImageFile: async (onSuccess: (f: CustomFile, u: string) => void, onError: () => void) => {
            if (!imgRef || !imgRef.current || !completedCrop) {
                onError();
                return;
            }

            const reader = new FileReader();
            const canvas = await createImageCanvas(imgRef.current, completedCrop, 1, 0);
            canvas.toBlob(blob => {
                if (!blob) {
                    onError();
                    return;
                }
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    if (!reader.result) {
                        onError();
                        return;
                    }

                    const dataUrl = reader.result as string;
                    const croppedFile = dataURLtoFile(dataUrl, 'image.png');
                    if (!croppedFile) {
                        onError();
                        return;
                    }

                    onSuccess(croppedFile, dataUrl);
                };
            });
        },
    }), [imgRef.current, completedCrop]);

    return (
        <div>
            {Boolean(imageSrc) && <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => {
                    applyCompletedCrop(c);
                    if (onCompletedCrop) {
                        onCompletedCrop(c);
                    }
                }}
                aspect={rectangularShape ? landscapeSize : 1}
                circularCrop={circularCrop}
                keepSelection={true}
                style={{ display: 'block', maxWidth, maxHeight }}
            >
                <img
                    ref={imgRef}
                    src={imageSrc}
                    onLoad={onImageLoad}
                />
            </ReactCrop>}
        </div>
    );
};

export default forwardRef<ImageCropRef, Props>(ImageCrop);
