import React, { memo } from 'react';
import styles from './SummernoteController.module.scss';
import { Controller, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module-react';

const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'];

const Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);

const BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style',
    'size'
];

class ImageFormat extends BaseImageFormat {
    static formats (domNode: any) {
        return ImageFormatAttributesList.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                (formats as any)[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }

    format (name: any, value: any) {
        if (ImageFormatAttributesList.includes(name)) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);

const colors: Array<string> = [
    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc',
    '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66',
    '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2',
    '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'
];

const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'ordered', 'indent',
    'link', 'image',
    'align', 'color', 'background', 'size',
    'clean', 'style', 'height', 'width',
    'header',
];

export type Props = {
    name: string;
    placeholder?: string;
    required?: boolean;
    isDisabled?: boolean;
    control: Control<any>;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const SummernoteController = ({ control, name, placeholder, required, isDisabled, ...props }: Props, ref: any) => {
    const { t } = useTranslation();

    const modules = {
        imageResize: !isDisabled
            ? {
                parchment: Quill.import('parchment'),
                displaySize: true,
                modules: ['Resize', 'DisplaySize', 'Toolbar'],
            }
            : undefined,
        imageDrop: !isDisabled,
        toolbar: !isDisabled
            ? [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }, { size: fontSizeArr }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, 'link'],
                [{ color: colors }, { background: [] }],
                [{ align: [] }, 'image'],
                ['clean']
            ]
            : false,
        clipboard: {
            matchVisual: false
        }
    };

    return (
        <div className={isDisabled ? styles.disabled : styles.summernote} {...props} ref={ref}>
            <Controller
                render={({ field }) => (
                    <ReactQuill
                        theme='snow'
                        placeholder={placeholder ?? t('shared_translations.common.write_here')}
                        readOnly={isDisabled}
                        value={field.value}
                        onChange={field.onChange}
                        modules={modules}
                        formats={formats}
                    />
                )}
                name={name}
                control={control}
                rules={{ required, validate: (value) => { return !value?.replaceAll(/<[^>]+>[<br>]+<\/[a-z0-9]+>/gi, '') ? (t('shared_translations.messages.required_field') ?? false) : true; } }}
            />
        </div>
    );
};

export default memo(React.forwardRef<HTMLTextAreaElement, Props>(SummernoteController));
