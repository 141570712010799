import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import './App.css';
import store from './store/store';
import i18n from './common/services/I18n';
import Setup from './Setup';
import DefaultRouter from './Router';
import { Toaster } from 'react-hot-toast';
import LoadingProvider from 'common/components/loading/LoadingProvider';

const App: React.FunctionComponent = () => {
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <Toaster
                    position='top-right'
                    toastOptions={{
                        // Define default options
                        duration: 5000
                    }}
                />
                <Setup>
                    <DefaultRouter />
                </Setup>
                <LoadingProvider />
            </I18nextProvider>
        </Provider>
    );
};

export default App;
