import { CSSProperties, forwardRef, memo, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { getData, loadData, removeEvents, setBackgroundFromUrl, setObjectsSelection, zoomToCenter } from './CanvasImageUtils';
import { useTranslation } from 'react-i18next';

export const canvasColors = ['#e74c3c', '#2ecc71', '#3498db', '#e67e22', '#9b59b6', '#000000', '#ffffff'];
export const canvasPencilSizes = [1, 2, 3, 4, 5, 6, 8, 10, 20, 30, 50];
export const canvasTextSizes = [8, 10, 12, 14, 16, 18, 20, 22, 24, 30, 50];

export const canvasDefaultPencilColor = canvasColors[0];
export const canvasDefaultTextColor = canvasColors[6];

export const canvasDefaultPencilSize = canvasPencilSizes[4];
export const canvasDefaultTextSize = canvasTextSizes[6];

const zoomMin = 0.01;
const zoomMax = 20;
const zoomMultiplier = 0.999;
const touchValueDrag = 15;
const touchTwoFingers = 2;

export interface CanvasClickEvent {
    x: number;
    y: number;
}

interface ColorSize {
    color: string;
    size: number;
}

export type CanvasDrawingMode = 'none' | 'selection' | 'pencil' | 'text' | 'move';

export interface CanvasImageRef {
    getData: () => string;
    revertData: (data: string | null | undefined) => void;
    deleteSelectedObject: () => void;
    getPosition: () => ({ left: number; top: number }) | null;
    zoomIn: () => void;
    zoomOut: () => void;
}

interface Props {
    url: string;
    width: number;
    height: number;
    drawingMode: CanvasDrawingMode;
    drawingData: string | null;
    className?: string;
    style?: CSSProperties;
    pencilColorSize?: ColorSize;
    textColorSize?: ColorSize;
    allowZoom?: boolean;
    onChangeDrawingMode?: (dm: CanvasDrawingMode) => void;
    onSelectObject?: (anySelected: boolean) => void;
    onLoad?: () => void;
}

const CanvasImage = ({
    url, width, height, drawingMode = 'none', onChangeDrawingMode, drawingData, className, style,
    pencilColorSize, textColorSize, onSelectObject, onLoad, allowZoom = true
}: Props, ref: React.Ref<CanvasImageRef>) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const fabricCanvasRef = useRef<fabric.Canvas | null>(null);
    const [forceReRender, setForceReRender] = useState(0);
    const pausePanning = useRef(false);
    const { t } = useTranslation();
    const lastPosition = useRef<null | { zoom: number; viewportTransform: number[] | undefined; }>(null);

    const enableText = () => {
        if (!fabricCanvasRef.current) {
            return;
        }

        removeEvents(fabricCanvasRef.current);
        setObjectsSelection(fabricCanvasRef.current, false);

        fabricCanvasRef.current.selection = false;
        fabricCanvasRef.current.isDrawingMode = false;

        fabricCanvasRef.current.on('mouse:down', (o) => {
            if (!fabricCanvasRef.current) {
                return;
            }

            const pointer = fabricCanvasRef.current.getPointer(o.e);
            const textObj = new fabric.IText(t('home_screen.inspirations.image_notes.new_text_placeholder'), {
                left: pointer.x,
                top: pointer.y,
                fill: textColorSize?.color ?? canvasDefaultTextColor,
                fontSize: textColorSize?.size ?? canvasDefaultTextSize,
                fontFamily: "'Inter', sans-serif",
                selectable: true,
            });
            fabricCanvasRef.current.setActiveObject(textObj);
            fabricCanvasRef.current.add(textObj);
            textObj.enterEditing();
            textObj.selectAll();

            fabricCanvasRef.current.renderAll();

            if (onChangeDrawingMode) {
                onChangeDrawingMode('selection');
            }
        });
    };

    const disable = () => {
        if (!fabricCanvasRef.current) {
            return;
        }

        removeEvents(fabricCanvasRef.current);

        fabricCanvasRef.current.discardActiveObject();
        setObjectsSelection(fabricCanvasRef.current, false);

        fabricCanvasRef.current.selection = false;
        fabricCanvasRef.current.isDrawingMode = false;
    };

    const enableSelection = () => {
        if (!fabricCanvasRef.current) {
            return;
        }

        removeEvents(fabricCanvasRef.current);
        setObjectsSelection(fabricCanvasRef.current, true);

        fabricCanvasRef.current.isDrawingMode = false;
        fabricCanvasRef.current.selection = true;
    };

    const enableMove = () => {
        if (!fabricCanvasRef.current) {
            return;
        }

        removeEvents(fabricCanvasRef.current);
        setObjectsSelection(fabricCanvasRef.current, false, undefined, true);

        fabricCanvasRef.current.isDrawingMode = false;
        fabricCanvasRef.current.selection = false;

        let currentX = 0;
        let currentY = 0;
        let xChange = 0;
        let yChange = 0;
        let lastX = 0;
        let lastY = 0;

        fabricCanvasRef.current.on('touch:drag', (e) => {
            if (!fabricCanvasRef.current) {
                return;
            }

            const layerX = (e as any).self.x;
            const layerY = (e as any).self.y;

            if (!pausePanning.current && undefined !== layerX && undefined !== layerY) {
                currentX = layerX;
                currentY = layerY;
                xChange = currentX - lastX;
                yChange = currentY - lastY;

                if ((Math.abs(currentX - lastX) <= touchValueDrag) && (Math.abs(currentY - lastY) <= touchValueDrag)) {
                    const delta = new fabric.Point(xChange, yChange);
                    fabricCanvasRef.current.relativePan(delta);
                }

                lastX = layerX;
                lastY = layerY;
            }
        });
    };

    const enablePencil = () => {
        if (!fabricCanvasRef.current) {
            return;
        }

        removeEvents(fabricCanvasRef.current);
        setObjectsSelection(fabricCanvasRef.current, false);

        const brush = new fabric.PencilBrush(fabricCanvasRef.current);
        brush.color = pencilColorSize?.color ?? canvasDefaultPencilColor;
        brush.width = pencilColorSize?.size ?? canvasDefaultPencilSize;
        fabricCanvasRef.current.freeDrawingBrush = brush;

        fabricCanvasRef.current.selection = false;
        fabricCanvasRef.current.isDrawingMode = true;
    };

    const applyDrawingMode = () => {
        if (!fabricCanvasRef.current) {
            return;
        }

        switch (drawingMode) {
            case 'none':
                disable();
                break;

            case 'selection':
                enableSelection();
                break;

            case 'pencil':
                enablePencil();
                break;

            case 'text':
                enableText();
                break;

            case 'move':
                enableMove();
                break;

            default:
                break;
        }
    };

    const listenObjectSelection = () => {
        if (!fabricCanvasRef.current) {
            return () => {};
        }

        fabricCanvasRef.current.on('selection:updated', () => {
            if (onSelectObject) {
                onSelectObject(true);
            }
        });
        fabricCanvasRef.current.on('selection:created', () => {
            if (onSelectObject) {
                onSelectObject(true);
            }
        });
        fabricCanvasRef.current.on('selection:cleared', () => {
            if (onSelectObject) {
                onSelectObject(false);
            }
        });

        return () => {
            if (!fabricCanvasRef.current) {
                return;
            }

            fabricCanvasRef.current.off('selection:updated');
            fabricCanvasRef.current.off('selection:created');
            fabricCanvasRef.current.off('selection:cleared');
        };
    };

    const listenZoom = () => {
        if (!fabricCanvasRef.current) {
            return () => { };
        }

        let zoomStartScale = 0;

        fabricCanvasRef.current.on('touch:gesture', (e: any) => {
            const canvas = fabricCanvasRef.current;
            if (!canvas) {
                return;
            }

            if (e.e.touches && e.e.touches.length === touchTwoFingers) {
                pausePanning.current = true;
                const point = new fabric.Point(e.self.x, e.self.y);
                if (e.self.state === 'start') {
                    zoomStartScale = canvas.getZoom();
                }
                const delta = zoomStartScale * e.self.scale;
                canvas.zoomToPoint(point, delta);
                pausePanning.current = false;
            }
        });

        fabricCanvasRef.current.on('mouse:wheel', (opt) => {
            const canvas = fabricCanvasRef.current;
            if (!canvas) {
                return;
            }

            const delta = opt.e.deltaY;
            let zoom = canvas.getZoom();
            zoom *= zoomMultiplier ** delta;
            if (zoom > zoomMax) {
                zoom = zoomMax;
            }
            if (zoom < zoomMin) {
                zoom = zoomMin;
            }
            canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);

            opt.e.preventDefault();
            opt.e.stopPropagation();
        });

        return () => {
            if (!fabricCanvasRef.current) {
                return;
            }

            fabricCanvasRef.current.off('mouse:wheel');
            fabricCanvasRef.current.off('touch:gesture');
        };
    };

    useLayoutEffect(() => {
        if (!canvasRef.current) {
            return undefined;
        }

        fabricCanvasRef.current = new fabric.Canvas(canvasRef.current, {
            width,
            height,
            selection: false,
            backgroundColor: '#D9D9D9',
        });
        fabricCanvasRef.current.requestRenderAll();

        loadData(fabricCanvasRef.current, drawingData, () => {
            if (!fabricCanvasRef.current) {
                return;
            }

            setBackgroundFromUrl(fabricCanvasRef.current, url, width, height);

            applyDrawingMode();

            if (lastPosition.current) {
                if (lastPosition.current.zoom !== 1 && fabricCanvasRef.current.getZoom() === 1) {
                    fabricCanvasRef.current.setZoom(lastPosition.current.zoom);
                }
                if (lastPosition.current.viewportTransform) {
                    fabricCanvasRef.current.setViewportTransform(lastPosition.current.viewportTransform);
                }
            }

            if (onLoad) {
                onLoad();
            }
        });

        return () => {
            if (fabricCanvasRef.current) {
                lastPosition.current = {
                    zoom: fabricCanvasRef.current.getZoom(),
                    viewportTransform: fabricCanvasRef.current.viewportTransform
                };
                fabricCanvasRef.current.dispose();
            } else {
                lastPosition.current = null;
            }
            fabricCanvasRef.current = null;
        };
    }, [canvasRef.current, width, height, url, forceReRender, drawingData]);

    useEffect(() => {
        applyDrawingMode();
        const unsubObjectSelection = listenObjectSelection();
        const unsubZoom = allowZoom ? listenZoom() : undefined;

        return () => {
            if (unsubObjectSelection) {
                unsubObjectSelection();
            }
            if (unsubZoom) {
                unsubZoom();
            }
        };
    }, [drawingMode, fabricCanvasRef.current, pencilColorSize, textColorSize, forceReRender]);

    useImperativeHandle(ref, () => ({
        getData: () => {
            if (!fabricCanvasRef.current) {
                return '';
            }

            return getData(fabricCanvasRef.current);
        },
        revertData: (_data: string | null | undefined) => {
            if (!fabricCanvasRef.current) {
                return;
            }

            setForceReRender(forceReRender + 1);
        },
        deleteSelectedObject: () => {
            if (!fabricCanvasRef.current) {
                return;
            }

            fabricCanvasRef.current.getActiveObjects().forEach((o) => {
                if (!fabricCanvasRef.current) {
                    return;
                }
                fabricCanvasRef.current.remove(o);
            });
        },
        getPosition: () => {
            if (!fabricCanvasRef.current) {
                return null;
            }
            const left = fabricCanvasRef.current.getElement().offsetLeft;
            const top = fabricCanvasRef.current.getElement().offsetTop;
            return { left, top };
        },
        zoomIn: () => {
            const canvas = fabricCanvasRef.current;
            if (!canvas) {
                return;
            }

            const zoomPercentage = 0.8;
            zoomToCenter(canvas, zoomPercentage);
        },
        zoomOut: () => {
            const canvas = fabricCanvasRef.current;
            if (!canvas) {
                return;
            }

            const zoomPercentage = 1.2;
            zoomToCenter(canvas, zoomPercentage);
        }
    }), [fabricCanvasRef.current, drawingMode, forceReRender]);

    return (
        <div className={className} style={{ touchAction: 'manipulation', ...(style ?? {}) }}>
            <canvas ref={canvasRef} />
        </div>
    );
};

export default memo(forwardRef<CanvasImageRef, Props>(CanvasImage));
