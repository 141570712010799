import Storage from '../../common/services/Storage';
import { STORAGE } from '../../Config';

export const showEditLayout = () => {
    return {
        type: 'showEditLayout',
    };
};

export const initEditLayout = () => {
    const editLayout = Storage.getObject(STORAGE.EDIT_LAYOUT) ?? false;
    return {
        type: 'initShowEditLayout',
        editLayout
    };
};
