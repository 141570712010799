import MediaService from 'api/medias/MediaService';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';

export const urlToFile = async (url: string, fileName: string) => {
    try {
        const blob = await MediaService.downloadByUrl(url, fileName);

        return new File([blob], fileName, {
            type: blob.type,
        });
    } catch (error) {
        Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t download image', error);
    }
    return null;
};
