
import React, { useState, useEffect } from 'react';
import styles from './FavoritesTab.module.scss';
import { ApplicationPaths, LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import toast from 'react-hot-toast';
import Loading from 'common/services/Loading';

import InfiniteScroll from 'react-infinite-scroll-component';
import { UserMediaSearchCriteria } from 'api/users/models/UserMediaSearchCriteria';
import ClientsService from 'api/clients/ClientsService';
import { ClientFavoritesDto } from 'api/clients/models/ClientDto';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NoRecords from 'common/components/noRecords/NoRecords';
import useNoInitialEffect from 'common/hooks/useNoInitialEffect';
import Favorites from 'common/components/favorites/Favorites';

interface Props {
    clientId: string | undefined
}

function FavoritesTab ({ clientId }: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [inspirations, setInspirations] = useState<ClientFavoritesDto[]>([]);
    const [criteria, setCriteria] = useState<UserMediaSearchCriteria>({
        userId: clientId,
        itemsPerPage: 12,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'i.title',
    });
    const [totalItems, setTotalItems] = useState<number>(0);

    useEffect(() => {
        void getData(criteria.page === 1);
    }, [criteria]);

    useNoInitialEffect(() => {
        void getData(true);
    }, [clientId, i18n.language]);

    const getData = async (reset = false) => {
        try {
            Loading.show();
            const page = await ClientsService.getClientFavoritesList({ ...criteria, userId: clientId });
            if (reset) {
                setInspirations(page.items);
            } else {
                setInspirations([...inspirations, ...page.items]);
            }
            setTotalItems(page.totalItems);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get favorits list", error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const onPageChange = () => {
        setCriteria({ ...criteria, page: criteria.page + 1 });
    };

    const inspirationsLenght = inspirations.length;
    return (<div>
        {inspirationsLenght > 0 && (
            <InfiniteScroll
                dataLength={inspirationsLenght}
                loader={null}
                hasMore={inspirationsLenght < totalItems}
                next={onPageChange}
                scrollableTarget={'tableScroll'}
            >
                <div className={styles.grid}>
                    {inspirations.map((item, index) => (
                        <div key={index} className={styles.inspirationCard}
                            onClick={() => navigate(`${ApplicationPaths.Inspirations}/details/${item.inspirationMasterRecordId}`, { state: { categoryId: item.categoryId, subCategoryId: item.subCategoryId } })}>
                            <div style={{ backgroundImage: `url(${item.mediaUrl})` }} className={styles.image}>
                                <Favorites className={styles.favoriteHeart} mode={false} onClick={() => null} />
                            </div>
                            <div className={styles.title}>{item.title}</div>
                        </div>
                    ))}
                </div>
            </InfiniteScroll>
        )}
        {inspirationsLenght <= 0 && <NoRecords />}
    </div>);
}

export default FavoritesTab;
