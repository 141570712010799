import styles from './ContentLayout.module.scss';

interface Props {
    children: React.ReactNode
}

const ContentLayout = ({ children }: Props) => {
    return (
        <div className={styles.contentLayout}>
            {children}
        </div>
    );
};

export default ContentLayout;
