
import authentication from './authentication/reducer';
import sideBar from './sideBar/reducer';
import editLayout from './editLayout/reducer';
import chosenUser from './chosenUser/reducer';

const reducers = {
    authentication,
    sideBar,
    editLayout,
    chosenUser
};

export default reducers;
