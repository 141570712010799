import { useTranslation } from 'react-i18next';
import styles from './ImagePointForm.module.scss';
import { Col, Row } from 'react-flexbox-grid';
import Button, { Color } from 'common/components/buttons/Button';
import FormItem from 'common/components/formItem/FormItem';
import Label from 'common/components/label/Label';
import { Reducers } from 'store/types';
import { useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import hasPolicies from 'common/utils/hasPolicies';
import Input from 'common/components/inputs/input/Input';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import useIsMobile from 'common/hooks/useIsMobile';
import InputGroup from 'common/components/inputs/inputGroup/InputGroup';
import { useForm } from 'react-hook-form';
import WarningToast from 'common/components/warningToast/WarningToast';
import toast from 'react-hot-toast';
import InputError from 'common/components/inputs/inputError/InputError';
import { ReactComponent as AddCircleIcon } from 'assets/svg/add-circle-with-border.svg';
import TextArea from 'common/components/textArea/TextArea';
import { InspirationAttachmentPoint } from 'api/inspirations/models/InspirationDto';
import useNoInitialEffect from 'common/hooks/useNoInitialEffect';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { useState } from 'react';

interface Props {
    model: InspirationAttachmentPoint;
    onCancel: (oldModel: InspirationAttachmentPoint) => void;
    onSave: (model: InspirationAttachmentPoint) => void;
    onDelete: () => void;
};

const ImagePointForm = ({ onCancel, onSave, onDelete, model }: Props) => {
    const { t } = useTranslation();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(userProfile, ['SETTINGUP_INSPIRATIONS_WRITE']);
    const isMobile = useIsMobile();
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);

    const { handleSubmit, formState, register, reset, getValues, setValue } = useForm<InspirationAttachmentPoint>({ defaultValues: model });
    const errors = formState.errors;

    const isDetails = false;

    const onError = () => {
        toast(`${t('shared_translations.messages.please_confirm_all_data')}`, {
            icon: <WarningToast />,
        });
    };

    const onRemoveYes = () => {
        setShowRemoveDialog(false);
        onDelete();
    };

    const onCancelYes = () => {
        setShowCancelDialog(false);
        onCancel(formState.defaultValues as InspirationAttachmentPoint);
    };

    useNoInitialEffect(() => {
        if (model.key !== getValues('key')) {
            reset(model);
        } else {
            setValue('x', model.x);
            setValue('y', model.y);
        }
    }, [model]);

    const showForm = Boolean(model.x && model.y);

    return (
        <div className={styles.outsideContainer}>

            <div className={isMobile ? styles.containerMobile : styles.container}>

                <form onSubmit={handleSubmit(onSave, onError)} >
                    <div>
                        <div className={styles.inlineContainer} style={{ fontWeight: 'bold', marginBottom: '1.5rem' }}>
                            <MdOutlineArrowBackIosNew
                                className={styles.iconBack}
                                onClick={() => showForm ? setShowCancelDialog(true) : onCancelYes()}
                            />
                            <a className={styles.label}>{t('home_screen.inspirations.image_points.add_point')}</a>

                        </div>
                        <div className={styles.inlineContainer} style={{ marginBottom: '0.8rem' }}>
                            <span className={styles.newPointIcon}>
                                <AddCircleIcon />
                            </span>
                            <a className={styles.label}>{t('home_screen.inspirations.image_points.click_to_add_point_message')}</a>
                        </div>
                    </div>

                    {showForm && <div>
                        <Row>
                            <Col xl={12}>
                                <FormItem>
                                    <Label required={Boolean(errors.name)}>
                                        {t('home_screen.inspirations.image_points.name') + '*'}
                                    </Label>
                                    <Input
                                        type="text"
                                        register={register}
                                        name="name"
                                        disabled={isDetails}
                                        required={true}
                                        showRequired={Boolean(errors.name)}
                                        maxLength={255}
                                    />
                                    <InputError error={errors.name} maxLength={255} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <FormItem>
                                    <Label>{t('home_screen.inspirations.image_points.price')}</Label>
                                    <InputGroup
                                        disabled={isDetails}
                                        name={'price'}
                                        format={'money'}
                                        register={register}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <FormItem>
                                    <Label>{t('home_screen.inspirations.image_points.description')}</Label>
                                    <TextArea
                                        disabled={isDetails}
                                        name={'description'}
                                        type={'textArea'}
                                        rows={5}
                                        register={register}
                                        maxLength={255}
                                    />
                                    <InputError error={errors.description} maxLength={255} />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12} className={styles.buttonsContent}>

                                {canWrite && (
                                    <Button
                                        text={t('shared_translations.common.delete')}
                                        onClick={() => setShowRemoveDialog(true)}
                                        color={Color.red}
                                        underLGFlex={true}
                                    />
                                )}
                                {canWrite && (
                                    <Button
                                        text={t('shared_translations.common.register')}
                                        type="submit"
                                        color={Color.black}
                                        underLGFlex={true}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>}

                    <QuestionYesNo
                        onNo={() => setShowRemoveDialog(false)}
                        onYes={onRemoveYes}
                        isVisible={showRemoveDialog}
                        message={t('shared_translations.messages.remove_record_with_ident', { name: '' })}
                    />

                    <QuestionYesNo
                        onNo={() => setShowCancelDialog(false)}
                        onYes={onCancelYes}
                        isVisible={showCancelDialog}
                        message={t('shared_translations.messages.lose_data_question')}
                    />
                </form>
            </div>
        </div>
    );
};

export default ImagePointForm;
