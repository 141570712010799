export interface RuleDto {
    id: string;
    name: string;
    expression: string;
    concatType?: RuleConcatType;
    checked?: boolean;
}

export enum RuleConcatType {
    OR = 'OR',
    AND = 'AND',
}
