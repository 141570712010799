import Card from './Card';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { PinterestPagePictureDto } from 'api/pinterest/models/PinterestDto';

declare type Fn = () => any;

export interface Props {
    items: PinterestPagePictureDto[];
    onPageChange: Fn;
    hasMore: boolean;
    onClickImage: (url: string) => void;
}

const Layout = ({ items, onPageChange, hasMore, onClickImage }: Props) => {
    return (
        <InfiniteScroll
            dataLength={items.length}
            loader={null}
            hasMore={hasMore}
            next={onPageChange}
            scrollableTarget={'tableScroll'}
            style={{ marginTop: '2rem' }}
        >
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
            >
                <Masonry gutter="10px">
                    {items.map((x, index) => {
                        return (<Card key={index} item={x} onClick={() => onClickImage(x.originalUrl)} />);
                    })}
                </Masonry>
            </ResponsiveMasonry>
        </InfiniteScroll>
    );
};
//
export default Layout;
