import React, { useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './Input.module.scss';

type Props = {
    type?: string
    register?: UseFormRegister<any>;
    name?: string
    disabled?: boolean
    required?: boolean
    showRequired?: boolean
    maxLength?: number;
    onChange?: any
    pattern?: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Input = (props: Props) => {
    const { type = 'text', register, name, disabled = false, required = false, showRequired = false, maxLength, onChange, pattern, ...rest } = props;
    const { t } = useTranslation();
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [message, setMessage] = useState('');
    const validatetypeText = (required && type === 'text');

    const validateEmail = (event: any) => {
        const email = event.target.value;
        const emailPattern = /([0-9a-z_.-]{1,64}@[0-9a-z_.-]{1,187}\.[0-9a-z._-]{2,4})/gi;

        if (emailPattern.exec(email)) {
            setIsEmailValid(true);
            setMessage('');
        } else {
            setIsEmailValid(false);
            setMessage(() => t('shared_translations.messages.enter_valid_email'));
        }
    };

    const registerProps: any = (register && name
        ? register(name, {
            required,
            maxLength,
            validate: validatetypeText ? (value) => { return !value?.trim() ? (t('shared_translations.messages.required_field') ?? false) : true; } : undefined
        })
        : {});

    const checkChange = (e: any) => {
        if (pattern) {
            e.target.value = e.target.value.replace(new RegExp(pattern, 'ig'), '');
        }

        if (type === 'email') {
            validateEmail(e);
        }

        if (onChange) {
            onChange(e);
        }

        if (registerProps?.onChange) {
            registerProps?.onChange(e);
        }
    };

    return (
        <>
            <input
                {...rest}
                type={type}
                {...registerProps}
                disabled={disabled}
                onChange={(e) => checkChange(e)}
                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                className={`${styles.input} ${props.disabled ? styles.disabled : ''} ${showRequired ? styles.warning : styles.noWarning} ${rest.className ? rest.className : ''}`}
            />
            {!isEmailValid && <div className={styles.invalidEmailMessage}>
                {message}
            </div>}
        </>
    );
};

export default Input;
