import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Reducers } from '../../store/types';
import { POLICIES } from '../../Config';
import { UserProfile } from '../../api/account/models/UserProfile';
import hasPolicies from 'common/utils/hasPolicies';

interface Props {
    policies: Array<keyof typeof POLICIES>
    type?: 'OR' | 'AND';
    children: JSX.Element;
};

const PolicyRoute: React.FC<Props> = ({ children, policies, type = 'OR' }: Props) => {
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const allowed = hasPolicies(userProfile, policies, type);

    if (!allowed) {
        return <Navigate replace={true} to={'/'} state={{ from: '/' }} />;
    }

    return children;
};

export default PolicyRoute;
