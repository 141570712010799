import { LanguageFileAttachment } from '../models/LanguageFile';
import styles from './ImageGrid.module.scss';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron_down.svg';
import { useEffect, useRef, useState } from 'react';
import newGuid from 'common/utils/newGuid';
import useIsMobile from 'common/hooks/useIsMobile';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import useNoInitialEffect from 'common/hooks/useNoInitialEffect';
import { ReactComponent as InputIcon } from 'assets/svg/inputImage.svg';
import { elementHasParent } from 'common/utils/elementHasParent';
import Hammer from 'hammerjs';
import FadeIn from 'common/components/animations/FadeIn';

const gridSize = 3;
const lastItemIndex = 2;
const allowedScrollClassName = 'allowed-scroll';

type CurrentAttachment = { key: string } & ({
    type: 'attachment';
    attachment: LanguageFileAttachment;
    selected: boolean;
} | { type: 'fill'; selected: boolean });

interface Props {
    attachments: LanguageFileAttachment[];
    isDetails: boolean;
    showRequireEditableImage: boolean;
    mobileCanSwipe?: boolean;
    renderEditableImage: (canDraw: boolean) => JSX.Element;
    renderUploaderImage: () => JSX.Element;
    renderSmallImage: (att: LanguageFileAttachment, index: number) => JSX.Element;
    mobileOnChangeImage: (att: LanguageFileAttachment) => void;
    fullWidth?: boolean
    isFavorite: boolean
}

function ImageGrid ({
    renderEditableImage, attachments, renderSmallImage, renderUploaderImage, isDetails, showRequireEditableImage, mobileOnChangeImage, mobileCanSwipe, fullWidth, isFavorite
}: Props): JSX.Element {
    const [page, setPage] = useState(0);
    const isMobile = useIsMobile();
    const paginationRef = useRef<HTMLDivElement | null>(null);
    const imagesGridRef = useRef<HTMLDivElement | null>(null);
    const [mobileCurrentIndex, setMobileCurrentIndex] = useState(0);
    const showUploader = !isDetails || (isDetails && isFavorite);
    const attachmentsAsItems: CurrentAttachment[] = attachments.map(att => ({ type: 'attachment', attachment: att, key: newGuid(), selected: att.selected }));

    const imageItems: CurrentAttachment[] = [...attachmentsAsItems];

    const currentAttachments: CurrentAttachment[] = imageItems.slice(page, gridSize + page);
    if (currentAttachments.length < gridSize) {
        for (let i = 0; i <= gridSize - currentAttachments.length; i++) {
            currentAttachments.push({ type: 'fill', key: newGuid(), selected: false });
        }
    }

    const selectedAttachmentId = attachments.find(x => x.selected)?.id ?? 'nothing';

    const canGoTop = page > 0;
    const canGoBottom = gridSize + page < imageItems.length;

    const goTop = () => {
        if (!canGoTop) {
            return;
        }
        setPage(page - 1);
    };

    const goBottom = () => {
        if (!canGoBottom) {
            return;
        }
        setPage(page + 1);
    };

    const isValidScrollableElement = (target: any) => {
        return target && (
            target.classList?.contains(allowedScrollClassName) ||
            elementHasParent(target, allowedScrollClassName)
        );
    };
    const onWheelImages = (e: WheelEvent) => {
        if (isValidScrollableElement(e.target)) {
            e.preventDefault();
            if (e.deltaY > 0) {
                goBottom();
            } else {
                goTop();
            }
        }
    };

    const onMobileSwipeUp = (e: any) => {
        if (isValidScrollableElement(e.target)) {
            e.preventDefault();
            goBottom();
        }
    };

    const onMobileSwipeDown = (e: any) => {
        if (isValidScrollableElement(e.target)) {
            e.preventDefault();
            goTop();
        }
    };

    useNoInitialEffect(() => {
        if (currentAttachments.length < gridSize && page > 0) {
            goTop();
        }
    }, [attachments]);

    useEffect(() => {
        if (!imagesGridRef.current) {
            return () => {};
        }

        imagesGridRef.current.addEventListener('wheel', onWheelImages);

        const hammer = new Hammer(imagesGridRef.current);
        hammer.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL, enable: true });
        hammer.on('swipeup', onMobileSwipeUp);
        hammer.on('swipedown', onMobileSwipeDown);

        return () => {
            if (imagesGridRef.current) {
                imagesGridRef.current.removeEventListener('wheel', onWheelImages);
                hammer.off('swipeup', onMobileSwipeUp);
                hammer.off('swipedown', onMobileSwipeDown);
            }
        };
    }, [imagesGridRef.current, onWheelImages]);

    return (
        <div className={styles.container}>

            {/* Web */}
            {!isMobile && <div
                className={styles.imagesGrid}
                style={{ gridTemplateColumns: attachments.length > 0 || fullWidth ? undefined : '1fr' }}
                ref={imagesGridRef}
            >
                {/* Editable Image */}
                <div className={`${styles.editableImage} ${showRequireEditableImage ? styles.warning : ''}`}>
                    <FadeIn className={styles.editableImageFadeIn} childClassName={styles.editableImageFadeIn} key={'fade-in-' + selectedAttachmentId}>
                        {renderEditableImage(true)}
                    </FadeIn>
                </div>

                {/* Current Small Images */}
                {(!isDetails || imageItems.length > 0) && currentAttachments.map((att, i) => {
                    const showTopArrow = att.type !== 'fill' && i === 0 && canGoTop;
                    const showBottomArrow = att.type !== 'fill' && i === lastItemIndex && canGoBottom;

                    return (
                        <div
                            className={`${styles.smallImage} ${att.type === 'fill' ? styles.cursorDefault : ''} ${att.type !== 'fill' ? allowedScrollClassName : ''}`}
                            key={att.key}
                        >
                            {/* Direction Top Button */}
                            {showTopArrow && (
                                <div className={styles.directionalButtonTop} onClick={goTop}>
                                    <ChevronDown className={styles.chevronDown} />
                                </div>
                            )}

                            {att.type === 'attachment' && renderSmallImage(att.attachment, i)}

                            {i === 0 && att.type === 'fill' && (
                                <div className={styles.noImageContainer}>
                                    <InputIcon />
                                </div>
                            )}

                            {i === 0 && showUploader && <div className={styles.smallImageUploaderIcon}>{renderUploaderImage()}</div>}

                            {/* Direction Bottom Button */}
                            {showBottomArrow && <span className={styles.directionalButtonBottom} onClick={goBottom} >
                                <ChevronDown className={styles.chevronDown} />
                            </span>}
                        </div>
                    );
                })}
            </div>}

            {/* Mobile */}
            {isMobile && <div>
                <div className={styles.swiperContainer}>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        loop={false}
                        className={styles.mobileSwiper}
                        pagination={{
                            el: paginationRef.current,
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        onRealIndexChange={(e) => {
                            const attIndex = e.realIndex;
                            const att = attachments[attIndex];
                            if (att) {
                                mobileOnChangeImage(att);
                            }
                            setMobileCurrentIndex(attIndex);
                        }}
                        noSwiping={!mobileCanSwipe}
                        noSwipingClass="swiper-slide"
                        initialSlide={0}
                    >
                        {attachments.map((_, ii) => (
                            <SwiperSlide key={ii} className={styles.mobileListItem}>
                                <div className={styles.mobileEditableImageSlide}>
                                    {renderEditableImage(mobileCurrentIndex === ii)}
                                </div>
                            </SwiperSlide>
                        ))}
                        {!attachments.length && (
                            <SwiperSlide key={'no-image'} className={styles.mobileListItem}>
                                <div className={styles.mobileEditableImageSlide}>
                                    <div className={styles.noImageContainer}>
                                        <InputIcon />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                    <div ref={paginationRef}></div>
                    {showUploader && <div className={styles.mobileUploaderIconContainer}>
                        {renderUploaderImage()}
                    </div>}
                </div>
            </div>}
        </div>
    );
}

export default ImageGrid;
