import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

const MoneyFormat: React.FC<NumericFormatProps> = ({ value, suffix, ...props }: NumericFormatProps) => {
    return (
        <NumericFormat
            value={value}
            displayType={props.displayType ?? 'text'}
            thousandSeparator={' '}
            decimalSeparator={','}
            decimalScale={2}
            fixedDecimalScale={false}
            suffix={suffix ?? ' €'}
            {...props}
        />
    );
};

export default MoneyFormat;
