import { FaExclamationTriangle } from 'react-icons/fa';
import styles from './TabHeader.module.scss';

interface Props {
    name: string
    isSelected: boolean
    id: string | number;
    hasError?: boolean;
    changeTab: Function,
    counter?: number
}

const TabHeader = (props: Props) => {
    const { name, isSelected, changeTab, id, hasError, counter } = props;

    return (
        <div className={styles.tabHeader}>
            <div
                className={`${isSelected ? styles.active : ''} ${styles.div} ${counter !== undefined ? styles.reducedPaddingRight : ''}`}
                onClick={() => changeTab(id)}
            >
                <span>{name}</span>
                {hasError && <span className={styles.requiredLabel}><FaExclamationTriangle /></span>}
                {counter !== undefined && <div className={`${styles.counter} ${isSelected ? styles.activeCounter : ''}`} data-text={counter}></div>}
            </div>
        </div>
    );
};

export default TabHeader;
