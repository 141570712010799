import { UserProfile } from 'api/account/models/UserProfile';
import { POLICIES } from 'Config';

export default function hasPolicies (user: UserProfile | null, policies: (keyof typeof POLICIES)[], type: 'OR' | 'AND' = 'OR') {
    if (!user) {
        return false;
    }

    const userPolicies = user.policies ?? [];
    return type === 'AND'
        ? (policies || []).map(p => !!(userPolicies).find(up => up === POLICIES[p])).filter(p => !p).length === 0
        : (policies || []).map(p => !!(userPolicies).find(up => up === POLICIES[p])).filter(p => p).length > 0;
}
