import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Modal from 'react-modal';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/pt';
import 'dayjs/locale/fr';

void (async () => {
    if (!('ResizeObserver' in window)) {
        const module = await import('@juggle/resize-observer');
        (window as any).ResizeObserver = module.ResizeObserver;
        (window as any).ResizeObserverEntry = module.ResizeObserverEntry;
    }
})();

dayjs.extend(utc);
dayjs.extend(timezone);

Modal.setAppElement('#root');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
