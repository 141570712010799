import { Action } from './../authentication/type';
const initialState = {
    open: false
};

export default function (state = initialState, action: Action) {
    if (action.type === 'changeSideBar') {
        return { ...state, open: !(state.open) };
    } else {
        return state;
    }
}
