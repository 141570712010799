import pt from 'assets/translations/pt.json';
import fr from 'assets/translations/fr.json';
import en from 'assets/translations/en.json';

/**
 * Languages
 */
export const DEFAULT_LANGUAGE = 'pt';
export const DEFAULTNS = 'translations';
export const LANGUAGES_RESOURCES = {
    pt: {
        [DEFAULTNS]: pt,
    },
    fr: {
        [DEFAULTNS]: fr,
    },
    en: {
        [DEFAULTNS]: en,
    },
};
export const USE_LOCAL_TRANSLATIONS = process.env.REACT_APP_USE_LOCAL_TRANSLATIONS?.trim() === 'true';

/**
* API
*/
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const SERVER_BASE_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Pagination
 */
export const DEFAULT_PAGINATION_ITEMS_PER_PAGE = 16;
export const MAX_PAGINATION_ITEMS_PER_PAGE = 999999;

/**
 * Storage
 */
export const STORAGE_KEY = 'cQfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s';
export const STORAGE = {
    CURRENT_LOCALE: 'CURRENT_LOCALE',
    AUTH_USER_TOKEN: 'AUTH_USER_TOKEN',
    AUTH_USER_PROFILE: 'AUTH_USER_PROFILE',
    CHOSEN_USER: 'CHOSEN_USER',
    EDIT_LAYOUT: 'EDIT_LAYOUT'
};

/**
 * Http errors
 */
export const HTTP_ERRORS = {
    UNAUTHORIZED: 401,
    CONFLICT: 409
};

/**
 * Logger
 */
export enum LOGGER_MESSAGE_TYPE {
    INFO = 1,
    ERROR,
}
export enum LOGGER_LOG_TYPE {
    REQUEST
}
export const LOGGER_PRINT_LOGS = Boolean(process.env.REACT_APP_LOGGER_PRINT_LOGS);
export const LOGGER_PRINT_LOGS_LEVEL = LOGGER_MESSAGE_TYPE.INFO;

/**
 * React Form
 */

/**
 * POLICIES
 */
export const POLICIES = {
    BACKOFFICE_READ: 'BACKOFFICE_READ',
    BACKOFFICE_WRITE: 'BACKOFFICE_WRITE',

    SETTINGUP_RULES_READ: 'SETTINGUP_RULES_READ',
    SETTINGUP_RULES_WRITE: 'SETTINGUP_RULES_WRITE',

    SETTINGUP_USERS_READ: 'SETTINGUP_USERS_READ',
    SETTINGUP_USERS_WRITE: 'SETTINGUP_USERS_WRITE',

    SETTINGUP_CLIENTS_READ: 'SETTINGUP_CLIENTS_READ',
    SETTINGUP_CLIENTS_WRITE: 'SETTINGUP_CLIENTS_WRITE',

    SETTINGUP_ROLES_READ: 'SETTINGUP_ROLES_READ',
    SETTINGUP_ROLES_WRITE: 'SETTINGUP_ROLES_WRITE',

    SETTINGUP_LANGUAGES_READ: 'SETTINGUP_LANGUAGES_READ',
    SETTINGUP_LANGUAGES_WRITE: 'SETTINGUP_LANGUAGES_WRITE',

    SETTINGUP_DEFINITIONS_READ: 'SETTINGUP_DEFINITIONS_READ',
    SETTINGUP_DEFINITIONS_WRITE: 'SETTINGUP_DEFINITIONS_WRITE',

    SETTINGUP_EDITLAYOUT_READ: 'SETTINGUP_EDITLAYOUT_READ',
    SETTINGUP_EDITLAYOUT_WRITE: 'SETTINGUP_EDITLAYOUT_WRITE',

    SETTINGUP_CATEGORIES_READ: 'SETTINGUP_CATEGORIES_READ',
    SETTINGUP_CATEGORIES_WRITE: 'SETTINGUP_CATEGORIES_WRITE',

    SETTINGUP_INSPIRATIONS_READ: 'SETTINGUP_INSPIRATIONS_READ',
    SETTINGUP_INSPIRATIONS_WRITE: 'SETTINGUP_INSPIRATIONS_WRITE',

    SETTINGUP_API_READ: 'SETTINGUP_API_READ',
    SETTINGUP_API_WRITE: 'SETTINGUP_API_WRITE',
};

export const ApplicationName = 'DistinctCreation';

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message'
};

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out'
};

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'login-callback',
    LoginFailed: 'login-failed',
    Profile: 'profile',
    Register: 'register',
    Reset: 'resetPassword'
};

if (!SERVER_BASE_URL) {
    throw new Error('The variable SERVER_BASE_URL is not defined');
}

const prefix = '/authentication';
export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: `${SERVER_BASE_URL}/_configuration/${ApplicationName}`,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: 'Identity/Account/Register',
    IdentityManagePath: 'Identity/Account/Manage',
    IdentityTwoFactorAuthenticationPath: `${SERVER_BASE_URL}/Identity/Account/Manage/TwoFactorAuthentication`,
    Reset: `${prefix}/${LoginActions.Reset}`,
    Home: '/home',
    Backoffice: '/backoffice',
    Clients: '/clients',
    Categories: '/categories',
    Inspirations: '/inspirations',
};

export const blobToBase64 = (blobFile: Blob): Promise<string> => {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve((reader.result) as string);
        };
        reader.readAsDataURL(blobFile);
    });
};
