import { configureStore } from '@reduxjs/toolkit';
import { applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import reducers from './reducers';

const store = configureStore({
    reducer: combineReducers(reducers),
    enhancers: [composeWithDevTools(applyMiddleware(thunk))]
});

export default store;
export type AppDispatch = typeof store.dispatch;
