import ContentConfigurationsService from 'api/contentConfigurations/ContentConfigurationsService';
import { ContentConfigurationDto } from 'api/contentConfigurations/models/ContentConfigurationDto';
import BackofficeTable, { BackofficeTableColumn } from 'common/components/backofficeTable/BackofficeTable';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { ApplicationPaths, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DefinitionsTab.module.scss';
import { ReactComponent as ActionsIcon } from 'assets/svg/more-vertical.svg';
import { ReactComponent as AvatarIcon } from 'assets/svg/icon-user-client.svg';
import { useNavigate } from 'react-router-dom';
import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import toast from 'react-hot-toast';
import { UserProfile } from 'api/account/models/UserProfile';
import hasPolicies from 'common/utils/hasPolicies';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';

const DefinitionsTab = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(userProfile, ['SETTINGUP_DEFINITIONS_WRITE']);

    const [contentConfigurations, setContentConfigurations] = useState<ContentConfigurationDto[]>();

    const [totalItems, setTotalItems] = useState<number>(0);

    const [criteria, setCriteria] = useState<PagedSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'internal_find',
    });

    const getData = async () => {
        try {
            Loading.show();

            const results = await ContentConfigurationsService.getAllWithMedia({ ...criteria });
            setContentConfigurations(results.items);
            setTotalItems(results.totalItems);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get users list", error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const orderTable = async (orderField: string) => {
        const page = 1;
        const orderColumn = orderField;
        let orderBy: string;

        if (orderColumn !== criteria.orderColumn) {
            orderBy = 'asc';
        } else {
            if (criteria.orderBy === 'asc') {
                orderBy = 'desc';
            } else {
                orderBy = 'asc';
            }
        }

        setCriteria({ ...criteria, page, orderColumn, orderBy });
    };

    const renderActionCell = (row: ContentConfigurationDto) => {
        return (
            <div className={styles.optionsIcon}>
                <Dropdown
                    options={
                        <>
                            <DropdownItem url={`${ApplicationPaths.Backoffice}/definitions/details/${row.id}`}>
                                {t('shared_translations.common.details')}
                            </DropdownItem>
                            {canWrite &&
                                <DropdownItem url={`${ApplicationPaths.Backoffice}/definitions/edit/${row.id}`}>
                                    {t('shared_translations.common.edit')}
                                </DropdownItem>
                            }
                        </>
                    }
                >
                    <div>
                        <ActionsIcon />
                    </div>
                </Dropdown>
            </div>
        );
    };

    const contentConfigurationsColumns: BackofficeTableColumn<ContentConfigurationDto>[] = [
        {
            name: '',
            renderCell: (row) => <div className={styles.imageColumn}>{
                row.mediaUrl
                    ? <img className={styles.image} src={row.mediaUrl} />
                    : <AvatarIcon className={styles.image} />}
            </div>,
            width: '8%',
            hideOn: ['sm', 'md'],
            columnName: '',
        },
        {
            name: t('backoffice.definitions.name'),
            renderCell: (row) => t('backoffice.definitions.' + (row.internalFind).toLowerCase()),
            width: '92%',
            hideOn: [],
            columnName: 'internal_find',
        },
        {
            name: '',
            renderCell: renderActionCell,
            width: 'fill',
            columnName: '',
            preventClick: true,
        },
    ];

    useEffect(() => {
        void getData();
    }, [criteria.page, criteria.orderColumn, criteria.orderBy]);

    useEffect(() => {
        void getData();
    }, []);

    return (
        <div style={{ width: '100%' }}>
            <BackofficeTable
                columns={contentConfigurationsColumns}
                rows={contentConfigurations ?? []}
                totalItems={totalItems}
                currentPage={criteria.page}
                changePage={getData}
                orderTable={orderTable}
                initialOrder={{ columnName: 'real_name', isOrderAsc: true }}
                onRowClick={row => navigate(`${ApplicationPaths.Backoffice}/definitions/details/${row.id}`)}
            />
        </div>
    );
};

export default DefinitionsTab;
