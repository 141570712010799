import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import { useTranslation } from 'react-i18next';
import styles from './InspirationPointTooltipContent.module.scss';

interface Props {
    title: string;
    subtitle: string;
    price: number | null;
}

function InspirationPointTooltipContent ({ title, subtitle, price }: Props): (JSX.Element | null) {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {title}
            </div>
            {Boolean(subtitle) && <div className={styles.subtitle}>
                {subtitle}
            </div>}
            {Boolean(price) && <div className={styles.bottom}>
                <div className={styles.fromText}>
                    {t('home_screen.inspirations.marker_tooltip_price_from')}
                </div>
                <div className={styles.price}>
                    <MoneyFormat value={price} />
                </div>
            </div>}
        </div>
    );
}

export default InspirationPointTooltipContent;
