import { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import styles from './Point.module.scss';

const smallIconSize = 6;
const bigIconSize = 10;
const halftPercentage = 50;

const getTooltipPositionClassName = (xPercentage: number, yPercentage: number) => {
    if (xPercentage <= halftPercentage) {
        if (yPercentage <= halftPercentage) {
            return styles.rightBottomPosition;
        } else {
            return styles.rightTopPosition;
        }
    } else {
        if (yPercentage <= halftPercentage) {
            return styles.leftBottomPosition;
        } else {
            return styles.leftTopPosition;
        }
    }
};

export type PointIconType = 'default' | 'new';

interface Props {
    x: number;
    y: number;
    icon?: PointIconType;
    tooltip?: JSX.Element | null;
    unclickable?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

function Point ({ x, y, tooltip, onClick, icon = 'default', unclickable }: Props): JSX.Element {
    const [isHovering, setIsHovering] = useState(false);

    return (
        <div
            className={styles.container}
            style={{
                top: `${y}%`,
                left: `${x}%`,
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <div className={`${styles.point} ${isHovering && styles.active} ${unclickable ? styles.unclickable : ''}`} onClick={!unclickable ? onClick : undefined}>
                <span className={styles.background}>
                    {icon === 'new' ? <FaPlus size={isHovering ? smallIconSize : bigIconSize} fill={'#000'} /> : ''}
                </span>
            </div>

            {tooltip && !unclickable && (
                <div
                    className={`${styles.tooltip} ${getTooltipPositionClassName(x, y)}`}
                    style={isHovering ? { visibility: 'visible' } : {}}
                >
                    {tooltip}
                </div>
            )}
        </div>
    );
}

export default Point;
