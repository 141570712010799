import styles from './FloatingComment.module.scss';
import Button, { Color, Size } from 'common/components/buttons/Button';
import useIsMobile from 'common/hooks/useIsMobile';
import InputWithButton from 'common/components/inputs/inputWithButton/InputWithButton';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InspirationDto from 'api/inspirations/models/InspirationDto';

interface Props {
    item: InspirationDto | null;
    addComment?: boolean;
    onClose: () => void;
    onSaveComment: (comment: string) => void;
};

const FloatingComment = ({ item, addComment = false, onClose, onSaveComment }: Props) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const [isInputActive, setInputActive] = useState(false);
    const [comment, setComment] = useState<string>('');
    const [hasError, setHasError] = useState(false);

    function useOutsideAlerter (ref: any) {
        useEffect(() => {
            function handleClickOutside (event: { target: any; }) {
                if (ref.current && !ref.current.contains(event.target)) {
                    onClose();
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div ref={wrapperRef} className={`${styles.container} ${isMobile && styles.containerMobile}`}>

            <div className={styles.content} style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                {!isMobile && <div className={styles.divImage}>
                    <div className={styles.img} style={{ background: `url(${item?.mediaUrl})` }} />
                </div>}
                <div className={`${styles.contentDescription} ${isMobile && styles.contentDescriptionMobile}`}>
                    <div className={styles.label}>
                        {isInputActive || addComment
                            ? t('home_screen.inspirations.add_comment')
                            : t('home_screen.inspirations.add_to_favorites')}</div>
                    {(!isInputActive && !addComment) && <Button
                        text={t('home_screen.inspirations.add_comment')}
                        color={Color.white}
                        style={{ width: '100%', paddingLeft: 0 }}
                        size={Size.small}
                        onClick={() => {
                            setInputActive(!isInputActive);
                        }}
                    />}
                    {(isInputActive || addComment) &&
                        <>
                            <InputWithButton
                                style={{ minWidth: isMobile ? '15rem' : '30rem ' }}
                                placeholder={t('home_screen.inspirations.write_here')}
                                onChange={(e) => {
                                    setComment(e.target.value);
                                    setHasError(false);
                                }}
                                onButtonClick={() => {
                                    if (comment && comment !== ' ') {
                                        onSaveComment(comment);
                                        setInputActive(false);
                                    } else {
                                        setHasError(true);
                                    }
                                }}
                            />
                            {hasError && <div className={styles.messageError}>
                                {t('shared_translations.messages.required_field')}
                            </div>}
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default FloatingComment;
