import { Col, Row } from 'react-flexbox-grid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ClientDto } from 'api/clients/models/ClientDto';
import ClientsService from 'api/clients/ClientsService';
import toast from 'react-hot-toast';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE, ApplicationPaths, HTTP_ERRORS } from 'Config';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Button, { Color } from 'common/components/buttons/Button';
import FormItem from 'common/components/formItem/FormItem';
import Input from 'common/components/inputs/input/Input';
import Label from 'common/components/label/Label';
import styles from './ClientScreen.module.scss';
import Navbar from 'common/layouts/navBar/Navbar';
import { BreadCrumb } from 'common/types/BreadCrumb';
import ContentLayout from 'common/layouts/contentLayout/ContentLayout';
import WarningToast from 'common/components/warningToast/WarningToast';
import ImageInput from 'common/components/inputs/imageInput/ImageInput';
import InputError from 'common/components/inputs/inputError/InputError';
import { useDispatch, useSelector } from 'react-redux';
import ButtonsDiv from 'common/components/buttons/buttonsDiv/ButtonsDiv';
import hasPolicies from 'common/utils/hasPolicies';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import { setChosenUser } from 'store/chosenUser/action';

const Types = {
    create: 'create',
    edit: 'edit',
    details: 'details',
};

function ClientScreen (): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id, type } = useParams<{ id: string; type: string }>();

    const { register, handleSubmit, setValue, formState, setError, watch } = useForm<ClientDto>();
    const errors = formState.errors;
    const [userToRemove, setUserToRemove] = useState<ClientDto>({
        id: '',
        realName: '',
        email: '',
    });
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = hasPolicies(userProfile, ['SETTINGUP_CLIENTS_WRITE']);

    const breadCrumbs: BreadCrumb[] = [
        {
            text: t('menu.clients_management'),
            url: ApplicationPaths.Clients,
        },
        {
            text: t('clients.client_profile'),
        }
    ];

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [isDetails, setIsDetails] = useState<boolean>(
        !!(type && type === Types.details)
    );
    const [avatarFile, setAvatarFile] = useState<File | null>(null);
    const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

    const userSelectedOnSearch = useSelector<Reducers, ClientDto | null>((state) => state.chosenUser.user);

    const [showChangeClientModal, setShowChangeClientModal] = useState<boolean>(false);

    useEffect(() => {
        setIsDetails(!!(type && type === Types.details));
        void getData();
    }, [id, type]);

    const getData = async () => {
        try {
            if (id && id != null) {
                Loading.show();
                const user = await ClientsService.getById(id);
                setValues(user);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t  get client', error);
            toast.error(`${t('shared_translations.messages.error_load_info')}`);
            Loading.hide();
        }
    };

    const setValues = (user: ClientDto) => {
        setUserToRemove(user);
        setValue('id', user.id);
        setValue('realName', user.realName);
        setValue('address', user.address);
        setValue('email', user.email);
        setValue('phoneNumber', user.phoneNumber);
        setAvatarUrl(user.mediaUrl ?? null);
        Loading.hide();
    };

    const onSubmit = async (user: ClientDto) => {
        try {
            Loading.show();
            if (id && id != null) {
                await ClientsService.update(user, avatarFile);
                toast.success(`${t('shared_translations.messages.record_save_success')}`);
                navigateTo();
            } else {
                await ClientsService.create(user, avatarFile);
                toast.success(`${t('shared_translations.messages.record_save_success')}`);
                navigateTo();
            }
        } catch (error: any) {
            if (error.response.status === HTTP_ERRORS.CONFLICT) {
                toast(`${t('shared_translations.messages.email_already_in_use')}`, {
                    icon: <WarningToast />,
                });
                Loading.hide();
                setError('email', { type: 'used_email' }, { shouldFocus: true });
            } else {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get users list", error);
                toast.error(`${t('shared_translations.messages.record_save_error')}`);
                Loading.hide();
            }
        }
    };

    const onError = async () => {
        toast(`${t('shared_translations.messages.required_fields_empty')}`, {
            icon: <WarningToast />,
        });
    };

    const showRemoveItemDialog = async () => {
        setShowRemoveModal(true);
    };

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    };

    const removeItem = async () => {
        if (id && id != null) {
            try {
                Loading.show();
                await ClientsService.remove(userToRemove);
                toast.success(`${t('shared_translations.messages.record_delete_success')}`);
                Loading.hide();
                navigateTo();
            } catch (error) {
                setShowRemoveModal(false);
                toast.error(`${t('shared_translations.messages.record_delete_error')}`);
                Loading.hide();
            }
        }
    };

    const navigateTo = (typeUrl?: string, idToGo?: string) => {
        if (typeUrl) {
            navigate(`${ApplicationPaths.Clients}/${typeUrl}/${idToGo}`);
            setIsDetails(typeUrl === Types.details);
        } else {
            navigate(ApplicationPaths.Clients);
        }
    };

    const onChooseAvatar = (file: File, dataUrl: string) => {
        setAvatarFile(file);
        setAvatarUrl(dataUrl);
    };

    const onDeleteAvatar = () => {
        setAvatarFile(null);
        setAvatarUrl(null);
        setValue('removeMedia', true);
    };

    if (!canWrite && type !== Types.details) {
        return <Navigate replace={true} to={ApplicationPaths.Clients} />;
    }

    const validateSelectedClient = (item: ClientDto) => {
        if (item.id !== userSelectedOnSearch?.id) {
            setShowChangeClientModal(true);
        } else {
            dispatch(setChosenUser(item));
            navigate(`${ApplicationPaths.Clients}/file/${item.id}`);
        }
    };

    const changeClient = () => {
        dispatch(setChosenUser(watch()));
        navigate(`${ApplicationPaths.Clients}/file/${watch('id')}`);
    };

    return (
        <div>
            <Navbar breadCrumbs={breadCrumbs} isBackoffice={true} />
            <ContentLayout>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit, onError)}
                >
                    <Row>
                        <Col xs={12}>
                            <ButtonsDiv>
                                <Button
                                    text={t('shared_translations.common.go_back')}
                                    onClick={() => navigateTo()}
                                    color={Color.white}
                                />

                                {type === Types.details && <Button
                                    text={t('clients.client_profile')}
                                    onClick={() => validateSelectedClient(watch())}
                                    color={Color.black}
                                />}

                                {type !== Types.create && canWrite && (
                                    <Button
                                        text={t('shared_translations.common.delete')}
                                        onClick={() => showRemoveItemDialog()}
                                        color={Color.red}
                                    />
                                )}
                                {type === Types.details && canWrite && (
                                    <Button
                                        text={t('shared_translations.common.edit')}
                                        onClick={() => {
                                            navigateTo('edit', userToRemove.id);
                                        }}
                                        color={Color.black}
                                    />
                                )}

                                {type !== Types.details && (
                                    <Button
                                        text={
                                            type === Types.create
                                                ? t('shared_translations.common.register')
                                                : t('shared_translations.common.save')
                                        }
                                        type="submit"
                                        color={Color.black}
                                    />
                                )}
                            </ButtonsDiv>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={9} lg={8} md={8} sm={12} xs={12}>
                            <FormItem>
                                <Label required={!!errors.realName}>{t('clients.form.name') + '*'}</Label>
                                <Input
                                    type="text"
                                    register={register}
                                    name="realName"
                                    disabled={isDetails}
                                    required={true}
                                    maxLength={250}
                                    showRequired={!!errors.realName}
                                />
                                <InputError error={errors.realName} maxLength={250} />
                            </FormItem>
                            <FormItem>
                                <Label required={!!errors.email}>{t('clients.form.email') + '*'}</Label>
                                <Input
                                    type="email"
                                    register={register}
                                    name="email"
                                    disabled={isDetails}
                                    required={true}
                                    showRequired={!!errors.email}
                                />
                                <InputError error={errors.email} />
                            </FormItem>
                            <FormItem>
                                <Label>{t('clients.form.address')}</Label>
                                <Input
                                    type="text"
                                    register={register}
                                    name="address"
                                    disabled={isDetails}
                                    maxLength={250}
                                />
                                <InputError error={errors.address} maxLength={250} />
                            </FormItem>
                            <FormItem>
                                <Label>{t('clients.form.phone')}</Label>
                                <Input
                                    type="text"
                                    register={register}
                                    name="phoneNumber"
                                    disabled={isDetails}
                                    maxLength={20}
                                />
                                <InputError error={errors.phoneNumber} maxLength={20} />
                            </FormItem>
                        </Col>
                        <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                            <div className={styles.photoDiv}>
                                <ImageInput
                                    onChooseFile={onChooseAvatar}
                                    previewUrl={avatarUrl}
                                    onDelete={onDeleteAvatar}
                                    disabled={isDetails}
                                    forceAspectRatio={true}
                                />
                            </div>
                        </Col>
                    </Row>
                </form>
                <QuestionYesNo
                    onNo={onCancelRemove}
                    onYes={removeItem}
                    isVisible={showRemoveModal}
                    message={t('shared_translations.messages.remove_record_with_ident', {
                        name: userToRemove?.realName ?? '',
                    })}
                />
                <QuestionYesNo
                    onNo={() => setShowChangeClientModal(false)}
                    onYes={changeClient}
                    isVisible={showChangeClientModal}
                    message={t('shared_translations.messages.change_client_chosen', {
                        clientName: watch('realName') ?? '',
                    })}
                />
            </ContentLayout>
        </div>
    );
}

export default ClientScreen;
