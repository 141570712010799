import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './IconInput.module.scss';
import CustomFile from 'common/models/CustomFile';
import { ReactComponent as ImageIcon } from 'assets/svg/galleryadd.svg';

const imagesAccept = {
    'image/jpeg': [],
    'image/png': []
};

type Props = {
    multiple?: boolean;
    accept?: { [key: string]: string[] };
} & ({
    onChooseFile: (file: CustomFile) => void;
    onChooseFiles?: never;
} | {
    onChooseFile?: never;
    onChooseFiles: (files: CustomFile[]) => void;
});

const IconInput = ({
    multiple = false, onChooseFile, onChooseFiles, accept = imagesAccept
}: Props) => {
    const onDrop = useCallback((files: File[]) => {
        if (!files || files.length === 0) {
            return;
        }

        if (onChooseFiles) {
            onChooseFiles(files);
        }

        if (onChooseFile) {
            onChooseFile(files[0]);
        }
    }, [onChooseFile]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple, accept });

    return (
        <>
            <div
                className={`${styles.fileWrapper}`}
                {...(getRootProps())}
            >
                <div className={styles.icon}>
                    <ImageIcon />
                </div>
                <input
                    className={styles.fileInput}
                    id="file"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    multiple={multiple}
                    {...getInputProps()}
                />
            </div>
        </>
    );
};

export default IconInput;
