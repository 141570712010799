import { useState } from 'react';
import { FaCaretDown, FaCaretUp, FaExclamationTriangle } from 'react-icons/fa';
import TranslationItem from '../translationItem/TranslationItem';
import styles from './TranslationsTree.module.scss';

export const translationKeyPrefix = '_translation:';

export function translationsHasErrors (translations: Translations): boolean {
    const keys = Object.keys(translations).filter((x) => !x.startsWith(translationKeyPrefix));
    return keys.map(k => translations[k]).filter(value => {
        if (typeof value === 'object') {
            return translationsHasErrors(value);
        }
        return !value?.trim();
    }).length > 0;
}

export interface Translations {
    [key: string]: string | Translations;
}

interface Props {
    name?: string;
    translations: Translations;
    isRoot?: boolean;
    disabled?: boolean;
    className?: string;
    formSubmitted?: boolean;
    onChange: (translations: Translations) => void;
}

function TranslationsTree ({ translations, name, onChange, className, disabled = false, isRoot = false, formSubmitted = false }: Props): JSX.Element {
    const [isOpen, setIsOpen] = useState(isRoot);
    const keys = Object.keys(translations).filter((x) => !x.startsWith(translationKeyPrefix));

    const onChangeTranslation = (key: string, value: any) => {
        const newTranslations = { ...translations };
        newTranslations[key] = value;
        onChange(newTranslations);
    };

    const hasError = formSubmitted && translationsHasErrors(translations);

    return (
        <div className={`${styles.container} ${className ?? ''}`}>
            {!isRoot && <div className={styles.header} onClick={() => setIsOpen(!isOpen)}>
                <div className={`${styles.title} ${hasError ? styles.requiredLabel : ''}`}>
                    {name} {hasError && <FaExclamationTriangle />}
                </div>
                <div>
                    {isOpen && <FaCaretDown className={styles.icon} size={18} />}
                    {!isOpen && <FaCaretUp className={styles.icon} size={18} />}
                </div>
            </div>}

            {isOpen && <div className={`${styles.listContainer} ${isRoot ? styles.listIsRootContainer : ''}`}>
                {keys.map((k, i) => {
                    const value = translations[k];
                    const keyName = translations[translationKeyPrefix + k] as string ?? k;
                    const isGroup = typeof value === 'object';

                    if (isGroup) {
                        return (
                            <TranslationsTree
                                key={i}
                                translations={value}
                                onChange={(val) => onChangeTranslation(k, val)}
                                name={keyName}
                                disabled={disabled}
                                formSubmitted={formSubmitted}
                            />
                        );
                    }

                    return (
                        <TranslationItem
                            key={i}
                            name={keyName}
                            value={value}
                            onChange={(val) => onChangeTranslation(k, val)}
                            disabled={disabled}
                            hasError={formSubmitted && !value?.trim()}
                        />
                    );
                })}
            </div>}

        </div>
    );
}

export default TranslationsTree;
