import 'react-edit-text/dist/index.css';
import { useTranslation } from 'react-i18next';
import styles from './EditableText.module.scss';
import { Control, UseFormRegister, Controller } from 'react-hook-form';
import React, { useEffect, useRef } from 'react';

export const InputType = {
    text: 'text',
    textArea: 'textArea',
};

type EditAreaProps = {
    name?: string
    placeholder?: string
    type: string
    disabled?: boolean;
    style?: React.CSSProperties;
    register?: UseFormRegister<any>;
    required?: boolean
    showRequired?: boolean
    maxLength?: number;
    onChange?: any
    pattern?: string;
    value: string | null | undefined;
    maxWidth?: string;
    disabledAsText?: boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export function EditableText (props: EditAreaProps) {
    const { name, placeholder, type, disabled, style, register, required = false, maxLength, onChange, value, disabledAsText = false } = props;
    const { t } = useTranslation();
    const registerProps: any = (register && name
        ? register(name, {
            required,
            maxLength,
            validate: required ? (newValue) => { return !newValue?.trim() ? (t('shared_translations.messages.required_field') ?? false) : true; } : undefined
        })
        : {});

    const checkChange = (e: any) => {
        if (onChange) {
            onChange(e);
        }

        if (registerProps?.onChange) {
            registerProps?.onChange(e);
        }
    };

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (textAreaRef) {
            if (textAreaRef.current) {
                textAreaRef.current.style.height = '0px';
            }

            const scrollHeight = textAreaRef?.current?.scrollHeight;

            if (textAreaRef.current) {
                textAreaRef.current.style.height = scrollHeight + 'px';
            }
        }
    }, [textAreaRef, value]);

    return (
        <div style={{ width: props.maxWidth ?? '100%', paddingLeft: type === InputType.text ? '' : '8px' }} >
            {(!disabledAsText || !disabled) && <textarea
                onChange={(e) => checkChange(e)}
                disabled={disabled}
                className={`${style} ${type === InputType.text ? styles.contentInput : styles.contentAreaInput}`}
                style={{ backgroundColor: 'transparent', paddingLeft: '3px' }}
                placeholder={placeholder ?? t('shared_translations.common.add_text')}
                value={value}
                ref={textAreaRef}
            />}
            {disabled && disabledAsText && <div style={{ paddingLeft: '3px' }}>{value}</div>}
        </div>
    );
};

type Props = Omit<EditAreaProps, 'value' | 'onChange'> & {
    required?: boolean
    name: string
    control: Control<any>
    maxLength?: number;
    maxWidth?: string;
};

const EditableTextInput = ({ name, required = false, control, maxLength, maxWidth, ...props }: Props) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{ required, maxLength }}
            render={({ field }) => (
                <EditableText {...props} onChange={field.onChange} value={field.value} maxWidth={maxWidth} />
            )}
        />
    );
};

export default EditableTextInput;
