import ContentLayout from 'common/layouts/contentLayout/ContentLayout';
import Navbar from 'common/layouts/navBar/Navbar';
import { BreadCrumb } from 'common/types/BreadCrumb';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HomeCategories from 'screens/home/homeCategories/HomeCategories';
import HomeInspirations from 'screens/home/homeInspirations/HomeInspirations';
import { useParams } from 'react-router-dom';

function HomeScreen (): JSX.Element {
    const { t } = useTranslation();
    const { categoryId, subCategoryId } = useParams<{ categoryId: string; subCategoryId: string }>();
    const breadCrumbs: BreadCrumb[] = [
        {
            text: t('menu.start'),
        },
    ];

    const [searchString, setSearchString] = useState('');
    const [search, setSearch] = useState(false);

    const handleChange = (event: any) => {
        setSearchString(event.target.value);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            setSearch(!search);
        }
    };
    return (
        <div onKeyDown={handleKeyDown}>
            <Navbar
                searchText={searchString}
                onChange={handleChange}
                onSearch={() => setSearch(!search)}
                breadCrumbs={breadCrumbs}
                inputPlaceHolder={t('shared_translations.messages.search_bar_clients_placeholder')}
            />
            <ContentLayout>
                <HomeCategories masterCategoryId={categoryId} masterSubCategoryId={subCategoryId}/>
                <HomeInspirations masterCategoryId={subCategoryId ?? categoryId} />
            </ContentLayout>
        </div>
    );
}

export default HomeScreen;
