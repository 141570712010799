import ImageCropModal from 'common/components/imageCropModal/ImageCropModal';
import CustomFile from 'common/models/CustomFile';
import fileToDataUrl from 'common/utils/fileToDataUrl';
import { useState } from 'react';
import FileInput from '../fileInput/FileInput';

interface Props {
    onChooseFile?: (file: CustomFile, dataUrl: string) => void;
    onClickOverlay?: () => void;
    onDelete?: (url: string) => void;
    previewUrl: string | null | undefined;
    disabled?: boolean;
    readOnly?: boolean;
    cropImage?: boolean;
    circularCrop?: boolean;
    roundDisplay?: boolean
    rectangularShape?: boolean
    forceAspectRatio?: boolean
    fill?: boolean;
    selected?: boolean;
}

const ImageInput = ({
    onChooseFile, onDelete, previewUrl, disabled, cropImage = true, circularCrop, roundDisplay = true,
    rectangularShape = false, forceAspectRatio = false, onClickOverlay, fill = true, selected = false, readOnly = false
}: Props) => {
    const [avatarOriginalImage, setAvatarOriginalImage] = useState<CustomFile | null>(null);
    const [showAvatarCropModal, setShowAvatarCropModal] = useState(false);
    const onChooseAvatar = async (file: CustomFile) => {
        if (cropImage) {
            setAvatarOriginalImage(file);
            setShowAvatarCropModal(true);
        } else {
            const dataUrl = await fileToDataUrl(file);
            if (onChooseFile) { onChooseFile(file, dataUrl); }
        }
    };

    const onCloseAvatarCropModal = () => {
        setAvatarOriginalImage(null);
        setShowAvatarCropModal(false);
    };

    const onCropAvatar = (file: CustomFile, dataUrl: string) => {
        onCloseAvatarCropModal();
        if (onChooseFile) { onChooseFile(file, dataUrl); }
    };

    const onDeleteAvatar = () => {
        setAvatarOriginalImage(null);
        if (onDelete) { onDelete(previewUrl ?? ''); }
    };

    return (
        <div style={fill ? { height: '100%', width: '100%' } : undefined}>
            <FileInput
                onChooseFile={onChooseAvatar}
                onClickOverlay={onClickOverlay}
                previewDataUrl={previewUrl}
                onDelete={onDeleteAvatar}
                disabled={disabled}
                readOnly={readOnly}
                roundDisplay={roundDisplay}
                forceAspectRatio={forceAspectRatio}
                selected={selected}
            />
            {cropImage && avatarOriginalImage !== null && (
                <ImageCropModal
                    isOpen={showAvatarCropModal}
                    isModal={true}
                    imageFile={avatarOriginalImage}
                    onClose={onCloseAvatarCropModal}
                    onSave={onCropAvatar}
                    circularCrop={circularCrop}
                    rectangularShape={rectangularShape}
                />
            )}
        </div>
    );
};

export default ImageInput;
