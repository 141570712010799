import { useMemo, useRef, useState } from 'react';
import styles from './EditableImage.module.scss';
import Point, { PointIconType } from './point/Point';
import { calculatePointPosition, DEFAULT_POINT_BUFFER } from './point/PointUtils';
import { ReactComponent as AddCircleIcon } from 'assets/svg/addcircle.svg';
import { ReactComponent as NoImageIcon } from 'assets/svg/inputImage.svg';
import { ReactComponent as CancelIcon } from 'assets/svg/x_mobile.svg';
import newGuid from 'common/utils/newGuid';
import { FaTrash } from 'react-icons/fa';
import QuestionYesNo from '../questionYesNo/QuestionYesNo';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from 'assets/svg/pencil-dyn.svg';
import CanvasImage, { CanvasDrawingMode, CanvasImageRef } from './canvasImage/CanvasImage';
import { ReactComponent as SearchImagePinterest } from 'assets/svg/pesquisa-pinterest.svg';
import { ReactComponent as CancelImagePinterest } from 'assets/svg/fechar-pesquisa-pintrest.svg';
import ImageCropModal from '../imageCropModal/ImageCropModal';
import { PixelCrop } from 'react-image-crop';
import InspirationFavoriteDto from 'api/inspirationFavorites/models/InspirationFavoriteDto';
import Favorites from '../favorites/Favorites';
import CanvasModal from './canvasModal/CanvasModal';
import useDimensions from 'react-cool-dimensions';
import throttle from 'lodash.throttle';
import { ResizeObserver } from '@juggle/resize-observer';

const buttonActiveColor = '#000000';
const throttleDelay = 0;

export interface PointData {
    key: string;
    x: number;
    y: number;
    icon?: PointIconType;
}

export type EditableImageMode = 'none' | 'add-point' | 'add-note';

interface Props<TPointData> {
    url: string;
    disabled?: boolean;
    points: TPointData[];
    editMode?: EditableImageMode;
    drawingMode?: CanvasDrawingMode;
    selectedPointKey?: string | null;
    selectedPointIsNew?: boolean | null;
    showEditNavigationButton?: boolean;
    drawingData?: string | null;
    allowDrawing?: boolean;
    renderPointTooltip?: (pointData: TPointData) => (JSX.Element | null);
    onAddPoint?: (pointData: PointData) => void;
    onPointClick?: (pointData: TPointData, index: number) => void;
    onNewPointClick?: () => void;
    onNewNoteClick?: () => void;
    onCancelEdition?: () => void;
    onDelete?: () => void;
    onEditNavigationButtonClick?: (e: React.MouseEvent) => void;
    onClick?: (e: React.MouseEvent) => void;
    onChangeDrawingMode?: (dm: CanvasDrawingMode) => void;
    onSaveDrawing?: (data: string) => void;
    onAddInspirationFavorite?: () => void;
    onRemoveInspirationFavorite?: () => void;
    favorites?: InspirationFavoriteDto[];
    isClientSelected?: boolean;
    hideAddPointsIcon?: boolean;
    onEditInspiration?: () => void;
    editInspirationMode?: boolean;
    onChangeSearchImage?: boolean;
    onSearchImageMode?: (data: string | undefined) => void;
    onCancelSearchImageMode?: (e: React.MouseEvent) => void;
    onCompletedSearchImage?: (data: PixelCrop, imageWidth: number, imageHeight: number) => void;
    pinterestPin?: string;
    inspirationImageUser?: string | null;
    searchImageShow?: boolean;
}

function EditableImage<TPointData extends PointData> ({
    url, points, renderPointTooltip, onAddPoint, onPointClick, disabled, onNewPointClick, onNewNoteClick,
    editMode, onCancelEdition, onDelete, selectedPointKey, selectedPointIsNew,
    showEditNavigationButton = false, onEditNavigationButtonClick, onClick, drawingMode, onChangeDrawingMode,
    onAddInspirationFavorite, onRemoveInspirationFavorite, favorites, isClientSelected,
    drawingData, onSaveDrawing, allowDrawing, hideAddPointsIcon = false, onChangeSearchImage, onSearchImageMode, onCancelSearchImageMode, onCompletedSearchImage,
    pinterestPin, onEditInspiration, editInspirationMode, inspirationImageUser, searchImageShow
}: Props<TPointData>): JSX.Element {
    const { t } = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const imageRef = useRef<HTMLImageElement>(null);
    const [imageWidth, setImageWidth] = useState<number | undefined>(undefined);
    const [imageHeight, setImageHeight] = useState<number | undefined>(undefined);
    const { observe: imageObserve } = useDimensions({
        polyfill: ResizeObserver,
        onResize: useMemo(
            () =>
                throttle((e: any) => {
                    setImageWidth(e.width);
                    setImageHeight(e.height);
                }, throttleDelay),
            []
        ),
    });
    const canvasImageRef = useRef<CanvasImageRef | null>(null);

    const onImageClick = (event: React.MouseEvent) => {
        if (editMode === 'add-point') {
            addPoint(event);
        }

        if (onClick) {
            onClick(event);
        }
    };

    const addPoint = (event: React.MouseEvent) => {
        if (!imageRef.current || !onAddPoint) {
            return;
        }

        const imageDimentions = imageRef.current.getBoundingClientRect();

        const [y, x] = calculatePointPosition(
            event,
            imageDimentions,
            window.scrollY,
            DEFAULT_POINT_BUFFER,
            DEFAULT_POINT_BUFFER
        );

        const key = selectedPointKey ?? newGuid();

        onAddPoint({
            x,
            y,
            key,
        });
    };

    const onAskDelete = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (disabled) {
            return;
        }

        setShowDeleteModal(true);
    };

    const onCancelDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setShowDeleteModal(false);
    };

    const onYesDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setShowDeleteModal(false);
        if (onDelete) {
            onDelete();
        }
    };

    const onAddFavorite = () => {
        if (onAddInspirationFavorite) {
            onAddInspirationFavorite();
        }
    };

    const onRemoveFavorite = () => {
        if (onRemoveInspirationFavorite) {
            onRemoveInspirationFavorite();
        }
    };

    const onSearchImage = () => {
        if (onSearchImageMode) {
            onSearchImageMode(pinterestPin);
        }
    };

    const showCanvasModal = drawingMode && onChangeDrawingMode && drawingMode !== 'none' && editMode === 'add-note' && allowDrawing;

    return (
        <div className={styles.container}>
            <img
                src={url}
                ref={(el) => {
                    imageObserve(el);
                    (imageRef as any).current = el;
                }}
                className={`${styles.image} ${onClick ? styles.cursorPointer : ''}`}
                onClick={onImageClick}
                style={{ visibility: (editMode === 'add-point' || !allowDrawing) && !onChangeSearchImage && url ? 'visible' : 'hidden' }}
            />
            {Boolean(url && imageWidth && imageHeight && allowDrawing) && <CanvasImage
                url={url}
                width={imageWidth ?? 0}
                height={imageHeight ?? 0}
                drawingMode={'none'}
                ref={canvasImageRef}
                drawingData={drawingData ?? null}
                className={`${styles.canvasImage} ${onClick ? styles.cursorPointer : ''}`}
                style={{ visibility: editMode !== 'add-point' ? 'visible' : 'hidden' }}
                allowZoom={false}
            />}

            {!url && <div className={styles.noImageContainer}>
                <NoImageIcon />
            </div>}

            {editMode !== 'add-note' && <div>
                {points.map((point, i) => (
                    <Point
                        key={i}
                        x={point.x}
                        y={point.y}
                        icon={selectedPointKey && selectedPointKey === point.key ? 'new' : point.icon}
                        tooltip={renderPointTooltip ? renderPointTooltip(point) : undefined}
                        onClick={onPointClick ? () => onPointClick(point, i) : undefined}
                        unclickable={Boolean(selectedPointKey !== point.key && selectedPointIsNew)}
                    />
                ))}
            </div>}

            {url && onChangeSearchImage &&
                <ImageCropModal
                    isOpen={true}
                    isModal={false}
                    imageUrl={url}
                    imageFile={undefined}
                    onCompletedCrop={data => {
                        if (onCompletedSearchImage && imageWidth && imageHeight) {
                            onCompletedSearchImage(data, imageWidth, imageHeight);
                        }
                    }}
                    onClose={() => null}
                    onSave={() => null}
                    circularCrop={false}
                    rectangularShape={true}
                    legend={t('home_screen.inspirations.label_search_in_pinterest')}
                />}

            {<div className={styles.topButtons}>
                {isClientSelected && !showEditNavigationButton &&
                    <Favorites
                        mode={favorites !== undefined && favorites.length === 0}
                        onClick={favorites !== undefined && favorites.length === 0 ? onAddFavorite : onRemoveFavorite} />
                }
                {!hideAddPointsIcon && !disabled && url && editMode === 'none' && <span className={styles.buttonIcon} onClick={onNewPointClick}>
                    <AddCircleIcon />
                </span>}
                {allowDrawing && url && editMode === 'none' && !editInspirationMode && <span className={styles.buttonIcon} onClick={onNewNoteClick}>
                    <EditIcon stroke={buttonActiveColor} />
                </span>}
                {editInspirationMode && <span className={styles.buttonIcon} onClick={onEditInspiration}>
                    <EditIcon stroke={buttonActiveColor} />
                </span>}
                {!disabled && url && editMode === 'add-point' && <span className={styles.buttonIcon} onClick={onCancelEdition}>
                    <CancelIcon />
                </span>}
                {!disabled && editMode === 'none' && url && <span className={styles.buttonIcon} onClick={onAskDelete}>
                    <FaTrash />
                </span>}
                {showEditNavigationButton && <span className={`${styles.buttonIcon} ${styles.buttonIconEdit}`} onClick={onEditNavigationButtonClick}>
                    <EditIcon stroke={'black'} />
                </span>}
            </div>}

            {<div className={styles.bottomButtons}>
                {!onChangeSearchImage && !editInspirationMode && disabled && searchImageShow && url && editMode === 'none' && <span className={`${styles.buttonIcon}`} onClick={onSearchImage}>
                    <SearchImagePinterest />
                </span>}
                {!onChangeSearchImage && !editInspirationMode && inspirationImageUser && searchImageShow && url && editMode === 'none' && <span className={`${styles.buttonIcon}`} onClick={onSearchImage}>
                    <SearchImagePinterest />
                </span>}
                {onChangeSearchImage && !editInspirationMode && disabled && searchImageShow && url && editMode === 'none' && <span className={styles.buttonIcon} onClick={onCancelSearchImageMode}>
                    <CancelImagePinterest />
                </span>}
                {onChangeSearchImage && !editInspirationMode && inspirationImageUser && searchImageShow && url && editMode === 'none' && <span className={styles.buttonIcon} onClick={onCancelSearchImageMode}>
                    <CancelImagePinterest />
                </span>}
            </div>}

            <QuestionYesNo
                onNo={onCancelDelete}
                onYes={onYesDelete}
                isVisible={showDeleteModal}
                message={t('shared_translations.messages.remove_record_with_ident', {
                    name: '',
                })}
            />
            {showCanvasModal && <CanvasModal
                url={url}
                drawingMode={drawingMode}
                drawingData={drawingData}
                onChangeDrawingMode={onChangeDrawingMode}
                onCancelEdition={onCancelEdition}
                onSaveDrawing={onSaveDrawing}
            />}
        </div>
    );
}

export default EditableImage;
