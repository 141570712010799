import Input from 'common/components/inputs/input/Input';
import { ChangeEvent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import styles from './TranslationItem.module.scss';

interface Props {
    name: string;
    value: string;
    disabled?: boolean;
    hasError?: boolean;
    onChange: (val: string) => void;
}

function TranslationItem ({ name, value, onChange, disabled = false, hasError = false }: Props): JSX.Element {
    const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };

    return (
        <Row middle='xs' className={styles.container}>
            <Col xs={12} sm={12} md={3} lg={3}>
                <span className={`${hasError ? styles.requiredLabel : ''}`}>{name}</span>
            </Col>
            <Col xs={12} sm={12} md={9} lg={9}>
                <Input
                    type="text"
                    className={styles.input}
                    value={value}
                    onChange={onValueChange}
                    disabled={disabled}
                    required={true}
                    showRequired={hasError}
                />
            </Col>
        </Row>
    );
}

export default TranslationItem;
