import { mergeDeep } from 'common/utils/deepen';
import i18n, { InitOptions, LanguageDetectorAsyncModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { DEFAULT_LANGUAGE, STORAGE, DEFAULTNS, USE_LOCAL_TRANSLATIONS, LANGUAGES_RESOURCES, API_BASE_URL } from '../../Config';
import Storage from './Storage';

const languageDetector: LanguageDetectorAsyncModule = {
    type: 'languageDetector',
    async: true,
    detect: (callback: (lng: string) => void) => {
        const language = Storage.get(STORAGE.CURRENT_LOCALE);
        return callback(language ?? DEFAULT_LANGUAGE);
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    init: () => { },
    cacheUserLanguage: (language: string) => {
        Storage.set(STORAGE.CURRENT_LOCALE, language);
    }
};

export const getDefaultLanguageData = (iso: string, namespace: string | null) => {
    const langResource = (LANGUAGES_RESOURCES as any)[iso];
    if (!langResource) {
        return (LANGUAGES_RESOURCES as any)[DEFAULT_LANGUAGE][namespace ?? DEFAULTNS];
    }
    return langResource[namespace ?? DEFAULTNS];
};

const config: InitOptions = {
    fallbackLng: DEFAULT_LANGUAGE,
    debug: false,
    initImmediate: false,
    load: 'languageOnly',
    ns: [DEFAULTNS],
    defaultNS: DEFAULTNS,
    interpolation: {
        escapeValue: false
    },
    react: {
        // wait: true,
        useSuspense: false
    },
    resources: USE_LOCAL_TRANSLATIONS ? LANGUAGES_RESOURCES : undefined,
    backend: {
        loadPath: `${API_BASE_URL}/languages/translations/{{lng}}`,
        parse: (data, language, namespace) => {
            const dataJson = JSON.parse(data);

            if (typeof language === 'string' && typeof namespace === 'string' && (LANGUAGES_RESOURCES as any)[language]) {
                const defaultTranslations = getDefaultLanguageData(language, namespace);
                return mergeDeep(defaultTranslations, dataJson);
            }

            return dataJson;
        },
        addPath: 'assets/translations/{{lng}}.json',
    }
};

void i18n
    .use(languageDetector)
    .use(initReactI18next)
    .use(HttpApi)
    .init(config);

export default i18n;
